.sattelvormassrechner {
  img {
    max-width: 400px;
    border: 0;
    margin-top: 4rem;
  }

  .hint {
    border: 1px solid $grey_40;
    padding: 1rem;
    margin-top: 2rem;
    display: inline-block;
  }
}