.panel {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  padding: calculateRem(45px);
  width: 100%;

  @media (max-width: $s) {
    padding: calculateRem(20px);
  }

  &.panel-small {
    max-width: calculateRem(380px);
  }
}
