/*-------------------
KBA Erweiterung START
---------------------*/
.content form.kba, div.form-result.kba {
  max-width: none;
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: $s) {
    //min-width: 1000px;
  }



  .form-col {
    width: 50%;

    @media screen and (max-width: $l - 1) {
      width: 100%;
    }

    margin-bottom: calculateRem(30px);

    &:first-child, &.left {
      padding-right: calculateRem(20px);

      @media screen and (max-width: $xs - 1) {
        padding-right: 0;
      }
    }

    p {
      margin: 0;
    }
  }

  .gridRow {
    margin: 0;
  }

  .gridCol {
    padding: 0 0 calculateRem(16px) 0;

    @media screen and (max-width: $xs - 1) {
      width: 100% !important;
    }
  }


  label {
    display: inline-block;
    width: calculateRem(25px);
    overflow: hidden;
    margin: 0;
    text-align: right;
    padding-right: calculateRem(5px);
    vertical-align: top;
    padding-top: 11px;

    &.divider {
      width: calculateRem(13px);

      + input, + span.result {
        width: calc( 100% - 17px );
      }
    }
  }

  p.label {
    width: 100%;
    margin-bottom: calculateRem(30px);
    @include font-size(14, 14);
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="file"],
  input[type="date"],
  input[type="password"],
  textarea,
  span.result,
  select {
    display: inline-block;
    width: calc( 100% - 30px );
    height: calculateRem(36px);
    padding-left: calculateRem(4px);
    letter-spacing: -0.4px;
    @include font-size(13,13);
    @include placeholder{
      @include font-size(13,13);
    }
  }

  select {
    background: $grey_20 url("/assets/images/icons/chevron-down-sm.svg") no-repeat right calculateRem(8px);
    background-size: calculateRem(20px);
  }

  textarea {
    height: calculateRem(89px);
    /* Display inline-block: Abstand Hack  */
    margin-bottom: -5px;
  }

  .fieldSize5050 {
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="date"],
    input[type="file"],
    input[type="password"],
    span.result,
    textarea,
    select {
      width: calc( 50% - 27px );
    }

    label {
      width: calculateRem(45px);
    }
  }

  .fieldSizeCombiWrapper {
    p {
      float: left;
      //padding-top: 7px;
      padding-bottom: 0;
    }
  }

  .fieldSizeCombi {
    display: block;
    float: right;
    width: calc( 100% - 30px);

    input[type="text"],
    input[type="email"],
    span.result,
    input[type="tel"],
    input[type="file"],
    input[type="password"],
    textarea,
    select {
      width: calc( 100% - 61px);
      float: left;
    }

    select {
      border-left: 1px solid $grey_40;
      width:  calculateRem(60px);

      @media screen and (max-width: $xl - 1) and  (min-width: $l){
        width:  calculateRem(42px);
      }

    }
  }


  .no_label {
    label {
      display: none;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="file"],
    input[type="date"],
    input[type="password"],
    textarea,
    select {
      width: 100%;
    }
  }

  @media (min-width: $xs) {

    .long_label {
      label {
        width: calculateRem(160px);
        text-align: left;
      }

      input[type="text"],
      span.result,
      input[type="email"],
      input[type="tel"],
      input[type="file"],
      input[type="date"],
      input[type="password"],
      textarea,
      select {
        width: calc(100% - 170px);
      }
    }

    .extralong_label {
      label {
        width: calculateRem(235px);
        text-align: left;
      }

      input[type="text"],
      span.result,
      input[type="email"],
      input[type="tel"],
      input[type="file"],
      input[type="date"],
      input[type="password"],
      textarea,
      select {
        width: calc(100% - 239px);
      }

      &.fieldSize5050 {
        input[type="text"],
        input[type="email"],
        input[type="tel"],
        input[type="date"],
        input[type="file"],
        input[type="password"],
        span.result,
        textarea,
        select {
          width: calc(50% - 122px);
        }
      }

      &.fieldSize333333 {
        input[type="text"],
        input[type="email"],
        input[type="tel"],
        input[type="date"],
        input[type="file"],
        input[type="password"],
        span.result,
        textarea,
        select {
          width: calc(33% - 82px);
        }
      }
    }
  }
}

.content form div.buttons, div.buttons {
  border-top: 1px solid $grey_80;
  padding-top: calculateRem(20px);
  margin-bottom: calculateRem(50px);

  > div.next, > div.reset {
    float: left;
    width: auto;
    margin-right: calculateRem(20px);

    @media screen and (max-width: $xs - 1) {
      margin-bottom: calculateRem(5px);
    }
  }

  p {
    float: left;
  }
  form {
    float: right;
    width: auto;
    margin-top: calculateRem(10px);
  }
}


/*-------------------
Table START
---------------------*/
.content form.kba {
  &.form-table {
    min-width: 0;
  }
  table {
    input[type="submit"] {
      padding: calculateRem(10px) calculateRem(10px);
      width: auto;
    }

    thead {
      tr{
        th {
          a {
            color: white;
            text-decoration: none;
          }
        }
        &:first-child{
          th {
            padding-bottom: 0;
          }
        }

        &:last-child {
          th {
            padding-top: 0;

            a{
              color: transparent;
              position: relative;
              &::after {
                @include icon();
                content:'\e612';
                color: white;
                position: absolute;
                left: calculateRem(-7px);
                top: calculateRem(3px);
              }

              &:last-child {
                &::after {
                  content:'\e610';
                  left: calculateRem(-5px);
                }
              }

              &:hover, &:focus {
                &::after {
                  color: $color-highlight;
                }
              }
            }
          }
        }
      }
    }

    td {
      input[type="text"] {
        width: 100%;
      }
      a{
        &::after {
          display: none;
        }
      }
    }
  }
}
/*--- Table END */

/*--- KBA END */
