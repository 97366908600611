@font-face {
  font-family: 'gtue_iconfont';
  src: url('../font/gtue_iconfont.eot?18096125');
  src: url('../font/gtue_iconfont.eot?18096125#iefix') format('embedded-opentype'),
       url('../font/gtue_iconfont.woff2?18096125') format('woff2'),
       url('../font/gtue_iconfont.woff?18096125') format('woff'),
       url('../font/gtue_iconfont.ttf?18096125') format('truetype'),
       url('../font/gtue_iconfont.svg?18096125#gtue_iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'gtue_iconfont';
    src: url('../font/gtue_iconfont.svg?18096125#gtue_iconfont') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "gtue_iconfont";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-star-empty:before { content: '\e601'; } /* '' */
.icon-search:before { content: '\e604'; } /* '' */
.icon-numbered-list:before { content: '\e605'; } /* '' */
.icon-menu:before { content: '\e606'; } /* '' */
.icon-mail:before { content: '\e607'; } /* '' */
.icon-heart:before { content: '\e60a'; } /* '' */
.icon-check:before { content: '\e60c'; } /* '' */
.icon-x:before { content: '\e60d'; } /* '' */
.icon-shopping-cart:before { content: '\e60e'; } /* '' */
.icon-chevron-right:before { content: '\e60f'; } /* '' */
.icon-chevron-up:before { content: '\e610'; } /* '' */
.icon-chevron-left:before { content: '\e611'; } /* '' */
.icon-chevron-down:before { content: '\e612'; } /* '' */
.icon-user:before { content: '\e613'; } /* '' */
.icon-log-out:before { content: '\e614'; } /* '' */
.icon-filter:before { content: '\e616'; } /* '' */
.icon-comment:before { content: '\e61a'; } /* '' */
.icon-info:before { content: '\e61b'; } /* '' */
.icon-share:before { content: '\e630'; } /* '' */
.icon-home:before { content: '\e636'; } /* '' */
.icon-flag:before { content: '\e63c'; } /* '' */
.icon-book:before { content: '\e64a'; } /* '' */
.icon-alert-error:before { content: '\e653'; } /* '' */
.icon-lock:before { content: '\e685'; } /* '' */
.icon-minus:before { content: '\e68e'; } /* '' */
.icon-add:before { content: '\e68f'; } /* '' */
.icon-help:before { content: '\e694'; } /* '' */
.icon-alert-triangle:before { content: '\e695'; } /* '' */
.icon-refresh:before { content: '\e696'; } /* '' */
.icon-file:before { content: '\e6a6'; } /* '' */
.icon-trash:before { content: '\e6ae'; } /* '' */
.icon-download:before { content: '\e6b0'; } /* '' */
.icon-bookmark:before { content: '\e6b4'; } /* '' */
.icon-arrow-down:before { content: '\e6ca'; } /* '' */
.icon-arrow-up:before { content: '\e6cb'; } /* '' */
.icon-arrow-right:before { content: '\e6cc'; } /* '' */
.icon-more-horizontal:before { content: '\e6de'; } /* '' */
.icon-clock:before { content: '\e82b'; } /* '' */
.icon-plakette:before { content: '\e82c'; } /* '' */
.icon-arrow-up-right:before { content: '\f1a6'; } /* '' */
.icon-printer:before { content: '\f1a7'; } /* '' */
.icon-phone:before { content: '\f1a8'; } /* '' */
.icon-video:before { content: '\f1a9'; } /* '' */
.icon-placeholder:before { content: '\f1b0'; } /* '' */
.icon-zoom-in:before { content: '\f1b1'; } /* '' */