.grosskundenliste {

  form {

    margin-bottom: 2rem;

    #grosskundenliste {
      input, select {
        margin-bottom: 1rem;
      }

      input {
        max-width: 22.5%;
      }
    }

    &>div>div:first-child {
      max-width: 22.5%;
      position: relative;
      pointer-events: none;

      &::before {
        content: '\e612';
        @include icon();
        position: absolute;
        top: 28px;
        right: 0;
        line-height: 25px;
        font-size: 25px;
        color: $color-text;
        border-left: 1px solid $grey_40;
        margin: 0;
        padding: 0 calculateRem(5px);
        display: block;
      }
    }

  }

  b {
    font-weight: 500;
  }

  .item {
    padding-right: 20px;
    width: calc(50% - 20px);
    float: left;
    margin-bottom: 1rem;
    border-top: 1px solid #ddd;
    padding-top: 1.5rem;

    p span {
      background: #999;
      color: white;
      padding: 4px 6px;
      box-decoration-break: clone;
      line-height: 2em;
    }

    @media(max-width: 500px){
      margin-bottom: 2rem;
      float: none;
      width: 100%;
    }
  }
}