.ablastungsrechner {

  .sfg_box {
    width: 50%;
    float: left;

    @media(max-width: 500px){
      width: 100%;
      float: none;
    }
  }

  #abl_ergebnis {
    width: 45%;
    float: right;
    border: 1px solid $grey_50;
    padding: 1rem;
    margin-top: 1.5rem;

    @media(max-width: 500px){
      width: 100%;
      float: none;
    }

    p {
      margin-top: 0;

      &.green {
          color: #08785f;
        }

      &.red {
        color: $brand_red;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}