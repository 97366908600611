
.content .pagetitle h1{
  padding: calculateRem(55px) calculateRem(25px) calculateRem(20px) calculateRem(25px);
  background: $brand_green url('./../images/header_hintergrund.jpg') no-repeat 0 0;
  background-size: cover;
  margin: 0;
  color: $grey_40;
  text-transform: uppercase;
  letter-spacing: 2px;
  @include font-size(55, 55);

  @media screen and (max-width: ($s - 1)) {
    margin-top: $grid_gutter;
    padding: calculateRem(20px) calculateRem(25px) calculateRem(20px) calculateRem(10px);
    @include font-size(30, 35);
  }

  &::before {
    display: none;
  }
}

.content {

  .content-container {
    h1:first-child {
      margin-top: 0;
    }
  }

  color: $color-text;
  //.tinymce h2, .tinymce h3, .tinymce h4, .tinymce h5{
  //  clear: both;
  //}

  h1, h2, h3, h4, h5{
    font-weight: 500;
    margin-bottom: calculateRem(16px);
  }

  h1 {
    @include font-size(32, 40);
    margin-bottom: calculateRem(35px);
    margin-top: calculateRem(11px);
    &::before {
      content: '';
      display: inline-block;
      width: calculateRem(6px);
      height: calculateRem(27px);
      margin-right: calculateRem(15px);
      background-color: $color-highlight;
    }
  }

  h2 {
    @include font-size(24, 30);
  }

  h3 {
    @include font-size(20, 25);
    margin-bottom: calculateRem(5px);
  }

  .sidebar-containers h3 {
    border-bottom: 2px solid $color-border;
    padding-bottom: calculateRem(4px);
    margin-bottom: calculateRem(4px);

    .start & {
      margin-top: 0;
    }
  }

  h4 {
    margin-top: 0;
    margin-bottom: calculateRem(5px);
    //@include font-size(18, 23);
  }

  h5 {
    @include font-size(16, 20);
    margin-bottom: calculateRem(15px);
  }

  .sidebar-containers h3 + p {
    margin-top: 0;
  }

}
