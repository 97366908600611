.slbElement {
  .slbContentOuter {
    background-color: $grey_80;
    color: white;
    padding: calculateRem(10px);
    position: relative;
    top: 1.5rem;

    .slbImage {
      padding: 0;
    }

    .slbCaption {
      position: relative;
      text-align: center;
      @include font-size(16,20);
      color: white;
      display: block;
      background-color: $grey_80;
    }

    .slbImageWrap {
      background: $white;
    }
  }

  .slbCloseBtn {
    background-color: $grey_80;
    border-radius: 100%;
    color: white;
    position: absolute;
    top:calculateRem(-20px);
    right: calculateRem(-20px);
    width: calculateRem(45px);
    height: calculateRem(45px);
    @include font-size(32,43);

    &:hover,&:focus {
      opacity: 1;
    }
  }


  .slbArrows {


    display: none;

    .slbActive.gallerylightbox &{
      display: block;
    }

    position: absolute;
    .slbArrow {
      position: absolute;
      z-index: 2;
      overflow: hidden;
      background: white;
      opacity: 1;
      width: calculateRem(34px);
      height: calculateRem(73px);
      text-align: center;
      font-weight: bold;
      border-radius: 0 40px 40px 0;
      left: 0;
      cursor: pointer;
      top: 50%;
      text-indent: unset;
      color: transparent;
      margin-top: calculateRem(-37px);
      text-indent: 0;


      &:hover, &:focus, &:active {
        outline: none;
        &:before {
          color: $color-highlight;
        }
      }

      &::before {

        position: absolute;
        padding: 0;
        border: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: $grey_80;
        @include icon();
        margin: 0;
        top: calculateRem(10px);
        @include font-size(50, 68);
        line-height: 1;
      }
    }

    .slbArrow.prev {
      left: calculateRem(10px);
      &::before {
        content: "\e611"; //"?"
        left: calculateRem(-11px);

      }

    }

    .slbArrow.next {
      right: calculateRem(10px);
      border-radius: 40px 0 0 40px;
      left: auto;

      &::before {
        content: "\e60f"; //"?"
        left: calculateRem(-3px);
      }
    }
  }
}
