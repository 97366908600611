.container {
  &.blackboard, &.rundschreiben {
    & ~ & {
      border-top: 1px solid $grey_50;
    }

    p.date {
      text-align: right;
    }

  }
}

.container.rundschreiben {
  div.block {
    margin-left: 2rem;

    h3 {
      position: relative;

      &::before {
        position: absolute;
        left: -2rem;
        top: calculateRem(3px);
        @include icon();
        font-size: calculateRem(19px);
      }

      &.technik::before {
        content: '\e82c';
      }

      &.termin::before {
        content: '\e82b';
      }

      &.wichtig::before {
        content: '\e695';
      }

      &.info::before {
        content: '\e61b';
      }
    }
  }
}

.container:target {
  animation: fadeOutHighlight 3s ease-out;
}

@keyframes fadeOutHighlight {
  0% {
    box-shadow: 20px 0 0 rgba(218, 31, 61, 0.5) inset;
  }

  100% {
    box-shadow: 0 0 0 transparent inset;
  }
}
