
/*-------------------
Sidebar START
---------------------*/
.sidebar-containers {
  nav {
    @media screen and (max-width: $s) {
      display: none;
    }

    /* Level 1 */
    > ul {
      margin-top: calculateRem(6px);
      padding-left: 0;
      //border-top: 1px solid $grey_40;
      //border-bottom: 1px solid $grey_40;

      /* Level All */
      ul {
        display: none;
      }

      > li {
        //border-bottom: 1px solid $grey_40;

        /* Level 2 */
        > ul {
          border-width: 1px !important;

          > li {
            > a {
              margin-left: calculateRem(10px);
            }

            /* Level 3 */
            > ul {
              padding-left: calculateRem(10px);
              //background-color: $grey_20;
              border-width: 1px !important;
              border-bottom: none;
              > li {
                a {
                  @include font-size(14,18);
                  font-weight: 400;
                }

                /* Level 4 */
                > ul {
                  > li {

                    a {
                      @include font-size(12, 16);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    ul {
      list-style-type: none;
      margin-bottom: 0;
      padding-left: 0;

      li {
        margin: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        position: relative;
        display: inline-block;
        width: 100%;

        &.active {
          > a {
            border-left: 4px solid $brand_red;
            padding-left: calculateRem(5px);
            color: $brand_red;
          }
        }

        &.active-path {
          > a {
            color: $brand_red;
          }
        }


        input[type=checkbox] {
          /* hide native checkbox */
          position: absolute;
          opacity: 0;

          &:focus + label {
            color: $brand_red;
          }
        }



        input[type=checkbox]:checked + label::after {
          /* show open folder icon if item is checked */
          content:'\e610';
        }
/*
        input[type=checkbox]:not(:checked) + label + ul {
          display: none;
        }
*/
        //&.active > ul,
        &.active > input[type=checkbox]:checked + label + ul,
        &.active-path > input[type=checkbox]:checked + label + ul,
        input[type=checkbox]:checked + label + ul,
        input[type=checkbox]:checked + label:nth-of-type(n) + ul {
          /* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
          /* show children when item is checked */
          display: block;
          border-top: 1px solid $grey_40;
          border-bottom: 1px solid $grey_40;

        }

        &:last-child {
          border-bottom: none;
        }

        a {
          padding: 0 calculateRem(25px) 0  calculateRem(9px);
          margin: calculateRem(10px) 0;

          @include font-size(15,18);

          display: block;
          text-decoration: none;
          position: relative;

          &[href^="http://"], &[href^="https://"] {
            text-decoration: none;
          }

          &::after {
            display: none;
          }

          &:hover, &:focus {
            text-decoration: none;

            &::after {
              border-color: $brand_red;
            }
          }
        }

        &.has-children > label {
          display: block;
        }

        label {
          position: absolute;
          cursor: pointer;
          right: 0;
          top: calculateRem(9px);
          width: calculateRem(32px);
          height: calculateRem(22px);
          display: none;


          &::after {
            display: block;
            width: 100%;
            height: 100%;
            @include icon();
            content: '\e612';
            @include font-size(27,23);
            text-align: center;
          }
        }

        p {
          margin: 0;
        }


      }
    }
  }
}
/*--- Sidebar END */
