.bundjacken {

  .info-inner {
    border: 1px solid #ccc;
    padding: 0 20px;
    max-width: 780px;
  }

  .info-inner.invalid {
    border-color: #BA002E;
  }

  #bjb-form .fieldType_select::before {
    right: .5rem;
  }

  .fieldType_radio>div {
    &>label {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }

  #bundjacken_bewerber {

    input {
      width: 15px;
      height: 15px;
      margin-left: 0;
      margin-right: 0.5rem;
    }

    label {
      display: inline-block;
      width: calc(100% - 30px);
      position: relative;
      font-size: 1rem;
      top: -2px;
    }
  }
}