nav.tabs {
  ul {
    border-bottom: 4px solid $grey_40;
    font-weight: 500;
    padding: 0;
    margin-bottom: calculateRem(30px);
    li {
      display: inline-block;
      padding: 0 calculateRem(15px) calculateRem(5px) calculateRem(15px);

      @media screen and (max-width: $m - 1) {
        padding: 0 calculateRem(5px) calculateRem(5px) calculateRem(5px);
      }

      width: auto;

      @media screen and (max-width: $xs - 1) {
        display: block;
        width: 100%;
        padding-left: 0;
      }

      margin-bottom: 0;
      position: relative;

      &:first-child {
        padding-left: 0;
      }

      a {
        text-decoration: none;
        @media screen and (max-width: $m - 1) {
         @include font-size(14,14);
        }
      }

      &.active {
        a {
          color: $color-highlight;
        }

        &::after {
          content: '';
          display: block;
          height: 4px;
          background-color: $color-highlight;
          position: absolute;
          bottom: -4px;
          width: 100%;
          left: 0;
          @media screen and (max-width: $xs - 1) {
            display: none;
          }
        }
      }
    }
  }
}
