#gtue-page-footer {

  text-align: center;
  position: relative;
  background-color: white;
  margin-top: calculateRem(85px);
  @include font-size(14,14);

  .gtue-stage {
    border-top: 5px solid $grey_40;
    background-color: white;
    z-index: 1;
    position: relative;

    @media (max-width: $s) {
      padding-top: calculateRem(10px);
    }

  }

  .gtue-footer-nav {

    display: inline-block;
    border-left: 1px solid black;
    margin-left: calculateRem(17px);
    padding-left: calculateRem(7px);

    @media (max-width: $s) {
      margin: calculateRem(10px) 0;
      padding: 0;
      border: none;
    }

    li {
      list-style-type: none;
      display: inline-block;
      padding: 0 calculateRem(10px);
    }
  }

  .gtue-scroll-up {
    display: block;
    width: calculateRem(85px);
    height: calculateRem(85px);
    border-radius: 100%;
    background-color: $grey_20;
    position: absolute;
    left: 50%;
    margin-left: calculateRem(-42px);
    top: calculateRem(-42px);
    z-index: 0;
    @include font-size(45,45);

    i {
      color:  $grey_60;
    }

    &:hover, &:focus {
      color: $color-highlight;
    }
  }
}
