.auftraggeber_td {

  #auftraggeber_td_plz_name {
    max-width: 250px;
  }

  .loader {
    background: url('./../images/loader.gif') no-repeat left top;
    width: 145px;
    height: 30px;
  }

  #auftraggeber_td {
    input {
      margin: 1rem 0;
    }
  }

  table {
    margin-top: 2rem;

    th {
      text-align: left;
      font-size: 1em;
    }

    tr:hover {
      background: #f6f6f6;
    }
  }
}
