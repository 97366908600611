body.sub-page {
  .content {
    display: block;
    padding-top: 2rem;
  }

  #gtue-header {
    height: 8rem;
    border-bottom: 4px solid #c2c4c6;
  }
}

body.no-cols {
  .content {
    display: block;
    padding-top: 3.8rem;
  }
}