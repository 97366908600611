.content {
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 2px solid $grey_20;
  }

  a, .singleLink {
    &:hover, &:focus, &:active {
      img {
        border-color: lighten($color-highlight, 10%);
      }
    }
  }
}

.media-container {
  margin: 0;
  position: relative;
  z-index: 1;
  padding: 0 0 calculateRem(30px) 0;

  position: relative;
  z-index: 1;

  @media screen and (max-width: ($xs - 1)) {
    margin-bottom: calculateRem(15px);
  }

  > a {
    display: block;
    text-decoration: none;

    &::after {
      display: none;
    }
  }

  &.left {
    float: left;
    padding-right: calculateRem(20px);
  }

  &.right {
    float: right;
    padding-left: calculateRem(20px);
  }

  &.left, &.right {
    width: 40%;

    &.big {
      width: 50%;
    }

    &.small {
      width: 23%;
    }

    @media screen and (max-width: ($xs - 1)) {
      &, &.big, &.small {
        width: 100%;
        float: none !important;
        padding: 0;
      }
    }

    // Deaktiviert: Die Größen führten zu unerwünschten Sprüngen in der Mobildarstellung.
    // ggf neu ermitteln, aber ein Bild sollte in der Abmessung nicht größer werden, wenn der Bildschirm kleiner wird.

    //&.small {
    //  width: 23%;
    //  @media screen and (max-width: ($m - 1)) {
    //    width: 40%;
    //  }
    //
    //  @media screen and (max-width: ($s - 1)) {
    //    width: 50%;
    //  }
    //  @media screen and (max-width: ($xs - 1)) {
    //    width: 100%;
    //    float: none!important;
    //    padding: 0;
    //  }
    //}
    //
    // Ersetzt durch redaktionelle Auswahl "Bild groß".
    //.info-container & {
    //  width: 50%;
    //}
    //
    //@media screen and (max-width: ($s - 1)) {
    //  width: 50%;
    //}
    //
    //@media screen and (max-width: ($xs - 1)) {
    //  width: 100%;
    //  float: none!important;
    //  padding: 0;
    //}
  }



  .media-description {
    @include font-size(13,18);
    font-weight: 400;
    position: relative;
    padding: calculateRem(5px) 0 0 0;
    display: block;
  }

  &.scaleable a .media-description, &.video .media-description {
    padding-left: calculateRem(33px);

    &::before {
      position: absolute;
      left: calculateRem(-4px);
      top: calculateRem(2px);
      @include icon();
      content: '\f1b1';
      @include font-size(25,28);
      line-height: 1;
    }
  }

  &.video .media-description {
    &::before {
      content: '\f1a9';
    }
  }
}
