.lizenzfreischaltung {

  position: relative;

  .overlay {
    background: rgba(255,255,255,0.9);
    position: absolute;
    top: 0;
    left:0;
    bottom: 0;
    right: 0;

    .overlay-inner {
      padding: 2rem;
      text-align: center;
      max-width: 400px;
      margin: 0 auto;
      border: 1px solid $grey-50;
      margin-top: 4rem;
      background: $white;

      @media (max-width: 400px){
        margin-top: 1rem;
        padding: 1rem;
      }
    }
  }

  button {
    overflow: hidden;
    color: white;
    border: none;
    font-weight: 500;
    padding: calculateRem(13px) calculateRem(45px);
    border-radius: calculateRem(50px);
    border-color: transparent;
    background-color: $grey_60;
    @include font-size(14,14);
    cursor: pointer;
    @include transition(all,.3s);
    margin-top: 1rem;

    &:hover {
      background-color: $color-highlight;
      color: white;
    }
  }
}