a {
  text-decoration: none;
  color: $color-text;
  font-weight: 500;

  &:hover, &:focus, &:active {
    color: $color-highlight;
    border-color: $color-highlight!important;
  }
}

.singleLink {
  cursor: pointer;

  &:hover, &:focus, &:active {
    a {
      color: $color-highlight;
      border-color: $color-highlight!important;
    }
  }
}


.content {


   a {

    /*
    border-bottom: 1px solid $color-border;

    &:hover, &:focus {
      border-bottom: 1px solid $color-highlight;
    }




    &.more {
      &::after {
        content: '\e60f';
      }
    }
    */

    &::after {
      @include icon();
      position: relative;
      top: calculateRem(4px);
      font-size: 1.7em;
      line-height: 1em;
      display: inline-block;
      text-decoration: none;
    }

    text-decoration: underline;

    &[href^="http://"], &[href^="https://"] {
      text-decoration: underline;
      &::after {
        content: '\f1a6';
        @include font-size(21,20);
        left: 0;
        top: calculateRem(3px);
        margin: 0;
      }
    }



    &.dl {
      text-decoration: none;
      display: inline-block;
      position: relative;
      padding-left: calculateRem(30px);

      &::before {
        @include icon();
        position: absolute;
        left: 0;
        top: calculateRem(2px);

        content: '\e6b0';
        margin-right: calculateRem(5px);
      }

      &::after {
        content: " " attr(data-type) " (" attr(data-size) ")";
        display: inline;
        font-family: roboto, sans-serif !important;
        font-size: inherit;
        font-weight: 400;
        position: relative;
        top: 0;
        text-transform: uppercase;
      }
    }

    &:hover, &:focus {
      color: $color-highlight;
      //text-decoration: underline;
    }
  }

  ul[class^="list-"].list-dl {
    //li {
    //  &::before {
    //    content: '\e6b0';
    //  }
    //
    //  a {
    //    text-decoration: none;
    //    font-weight: 400;
    //    &::before {
    //      content: attr(data-type) " | ";
    //      display: inline;
    //      font-family: roboto, sans-serif !important;
    //      font-size: inherit;
    //      font-weight: 500;
    //      position: relative;
    //      top: 0;
    //      text-transform: uppercase;
    //    }
    //    &::after {
    //      content: "(" attr(data-size) ")";
    //      display: inline;
    //      font-family: roboto, sans-serif !important;
    //      font-size: inherit;
    //      position: relative;
    //      margin-left: calculateRem(10px);
    //      top: 0;
    //    }
    //  }
    //}

    display: block;
    //margin: 0 0 0 0;
    padding: 0;

    li {
      display: block;
      margin: 0;
      padding: 0;

      &::before {
        content: none;
        display: none;
      }

      a {
        @extend .dl;
      }
    }
  }


  .link-list {
    padding: 0;

    li {
      list-style-type: none;
      margin-bottom: calculateRem(25px);

      a {
        //text-decoration: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  a.btn {
    overflow: hidden;
    color: white;
    border: none;
    font-weight: 500;
    padding: calculateRem(13px) calculateRem(45px);
    border-radius: calculateRem(50px);
    border-color: transparent;
    background-color: $grey_60;
    @include font-size(14,14);
    cursor: pointer;
    @include transition(all,.3s);
    text-decoration: none;

    &:hover {
      background-color: $color-highlight;
      color: white;
    }
  }

  a.btn-red-outline {
      overflow: hidden;
      font-weight: 500;
      padding: .8125rem 2.8125rem .8125rem 1.4rem;
      border-radius: 3.125rem;
      border: 2px solid $brand_red;
      background-color: #fff;
      font-size: .875rem;
      line-height: .875rem;
      cursor: pointer;
      color: $brand_red;
      transition: all ease-out .3s;
      text-decoration: none;
      position: relative;
      margin-top: 1rem;
      display: inline-block;

      &:hover {
        color: #fff;
        background: $brand_red;
      }


      &:after {
        font-family: "gtue_iconfont" !important;
        font-style: normal;
        font-weight: normal;
        font-size: 1.25rem;
        right: .625rem;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        speak: never;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        margin-left: .2em;
        content: "\e6cc";
    }
  }
}

a.mail {
  @extend .icon-mail;
  &::before {
    @include icon;
    text-decoration: none;
  }
}

a.tel {
  @extend .icon-phone;
  &::before {
    @include icon;
    text-decoration: none;
  }
}
