.accordion {
  margin-top: calculateRem(20px);
    > ul {
      margin-top: calculateRem(6px);
      border-top: 1px solid $grey_40;
      border-bottom: 1px solid $grey_40;
      list-style-type: none;
      padding: 0;

      > li {
        margin-bottom: 0;
        border-bottom: 1px solid $grey_40;
        position: relative;

        > label {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        > input {
          /* hide native checkbox */
          position: absolute;
          opacity: 0;

          &:focus, &:hover, &:active {
            & + label {
              color: $brand_red;
            }
          }

          &:checked + label {
            &::after {
              /* show open folder icon if item is checked */
              content: '\e610';
            }

            & + div,
            &:nth-of-type(n) + div {
              /* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
              /* show children when item is checked */
              max-height: calculateRem(1000px);
            }
          }
        }

        > div {
          max-height: 0;
          overflow: hidden;
          @include transition(max-height,.3s);
        }
        /*  kann wieder raus*/

        /* für ganz große Inhalte wird nach .5s die maxheight entfernt. */
        &.active > div {
          max-height: none !important;
        }

        &:last-child {
          border-bottom: none;
        }


        label {
          position: relative;
          cursor: pointer;
          width: 100%;
          display: block;
          padding: calculateRem(10px) 0 calculateRem(12px) 0;
          @include font-size(18,22);
          font-weight: 500;
          padding-right: calculateRem(30px);
          &::after {
            position: absolute;
            right: 0;
            top: calculateRem(9px);
            display: block;
            width: auto;
            height: auto;
            @include icon();
            content: '\e612';
            @include font-size(27,23);
            line-height: 1;
            text-align: center;
          }
        }
      }
    }
}
