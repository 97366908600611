.wkz {

  .dataTables_wrapper table.dataTable {
    thead th,
    td {
      font-size: 1rem;
      text-align: center;
      padding: 1rem;

      form {
        margin-bottom: 0;
      }
    }

    thead th {

      &.sorting_desc {
        &:after {
          bottom: 11px;
        }
      }

      &.sorting_asc {
        &:after {
          top: 11px;
        }
      }

      &.sorting {
        &:after {
          bottom: 11px;
        }

        &:before {
          top: 11px;
        }
      }
    }
  }

  #wkz-table th,
  #wkz-details th {
    white-space: nowrap
  }

  #wkz-table td {
    vertical-align: middle;
  }

  #wkz-table table td,
  #wkz-details table td {
    text-align: left;
  }

  #wkz-table table td:first-child,
  #wkz-details table td:first-child {
    font-weight: 500;
  }

  .dataTables_wrapper table.dataTable thead th:after,
  .dataTables_wrapper table.dataTable thead th:before {
    right: 10px;
  }

  @media(max-width: 1024px ){
    #wkz-table form input {
      padding: 0.5rem 1rem;
      font-size: 14px;
    }

    .dataTables_wrapper table.dataTable thead th:after,
    .dataTables_wrapper table.dataTable thead th:before {
      right: 5px;
    }

    #wkz-table th,
    #wkz-table td,
    #wkz-details th,
    #wkz-details td {
      font-size: 14px;
    }
  }
}
