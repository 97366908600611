.recruiting-pakete {

  background: $brand_red;
  padding: 0.5rem 0 0.5rem 0.5rem;
  overflow: hidden;
  display: none;

  &.show {
    display: flex;
  }

  .rp-col {
    width: 33%;
    float: left;
    background: $brand_red;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .rp-col-inner {
    background: $white;
    padding: $col_padding;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 2rem 0;
      flex: 1;

      li {
        margin-bottom: 1rem;
        padding-top: 3rem;
        position: relative;

        &:before {
          content: '+';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 20px;
          text-align: center;
          font-size: 2rem;
          font-weight: 500;
          color: $brand_red;
        }
      }
    }

    .rp-header {
      border-bottom: 5px solid $brand_red;
      height: 140px;
    }

    .rp-col-footer {
      height: 300px;
      border-top: 5px solid $brand_red;
    }

    .small {
      font-size: 0.8rem
    }

    h3, .price {
      color: $brand_red;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }
}

@media (max-width: $l) {

  .recruiting-pakete {

    .rp-col,
    .rp-col-inner,
    &.show {
      display: block;
      float: none;
      width: 100%;
    }

    .rp-col {
      padding: 0.5rem 1rem 0.5rem 0.5rem;
    }

    .rp-col-footer {
      height: auto;
    }

    .rp-col-footer .small {
      font-size: 1rem
    }
  }
}

.recruiting-pakete-form {

  @-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  background-color: $brand_red;
  position: relative;
  padding: 2rem;
  display: none;

  &.show {
    display: block;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }

  form {
    padding: 3rem 2rem 0.5rem;
    background-color: white;
    max-width: 100%;
    margin-bottom: 0 !important;
    border-radius: 1rem;

    .select {
      position: relative;

      &::before {
        content: '\e612';
        font-family: "gtue_iconfont" !important;
        font-style: normal;
        font-weight: normal;
        speak: none;

        text-decoration: inherit;
        width: 1em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        top: 0.5rem;
        right: 12px;
        line-height: 25px;
        font-size: 25px;
        color: $brand_red;
        border-left: 1px solid #dadbdd;
        margin: 0;
        padding: 0 5px;
        display: block;
        pointer-events: none;
      }
    }
  }

  .close-form {
    position: absolute;
    top: 1.2rem;
    right: 2rem;
    font-size: 5rem;
    color: $grey_80;
    cursor: pointer;
    border: none;
    background: none;
  }

  .buttons {
    margin-bottom: 0 !important;

    div {
      display: inline-block;
      margin: 0 1rem 1rem 0;
    }
  }

  .back-to-pakete {
    margin: 0 auto
  }

  .pflichtfeld {
    margin: 0 !important;
  }
}

.recruiting-pakete-errors ul {
  list-style-type: none;
  padding-left: 0;

  li {
    padding: .5em;
    border: 2px solid $brand_red;
    margin-bottom: 1rem;
  }
}

.recruiting-pakete-success {
  margin-bottom: 1rem;

  .success-inner {
    padding: 0.25rem 1rem;
    border: 2px solid $brand_grey;
  }
}
