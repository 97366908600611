.container {
  margin: 0 auto;

  .container {
    width: auto;
    margin: 0;

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .content {
    /*
    column-gap: $grid_gutter;
    row-gap:$grid_gutter;
    */

    > div {
      margin-bottom:$grid_gutter;
      @media screen and (min-width: $s) {
        margin-bottom: 0;
        border: $grid_gutter*0.5 solid transparent;
      }
    }

    @media screen and (min-width: $s) {
      display: grid;
    }

    //grid-column-gap: $gutter;

    .pagetitle {

      @media screen and (min-width: $s) {

        grid-column: 1 / 4;
        grid-row: 1;
        border-top: 0;
        //border-left: 0;
      }

      @media screen and (min-width: $m) {

        grid-column: 1 / 3;
        grid-row: 1;
        border-top: 0;
        border-left: 0;
      }
    }

    .sidebar-containers {
      overflow: hidden;

      @media screen and (min-width: $s) {
        order: 0;
        grid-column: 1;
        grid-row: 1;

        .start & {
          grid-row: 2;
        }
      }

      &.left {
        @media screen and (min-width: $m) {
          border-left: 0;
        }
      }

      &.right {
        @media screen and (min-width: $s) {
          order: 2;
          grid-column: 2 / 4;
          grid-row: 2;
          border-top: 0;

          column-width: 180px;
          padding-top: calculateRem(32px);

          .start & {
            padding-top: 0;
            grid-row: 3;
          }
        }

        @media screen and (min-width: $m) {
          grid-column: 3;
          grid-row: 1;
          border-right: 0;

          column-width: unset;

          .start & {
            grid-row: 1 / 3;
          }
        }
      }

      .content-container {
        display: inline-block;
        width: 100%;
      }

    }

    .content-containers {
      overflow: hidden;

      @media screen and (min-width: $s) {
        order: 1;
        grid-column: 2 / 4;
        grid-row: 1;

        .start & {
          grid-row: 2;
        }
      }

      @media screen and (min-width: $m) {
        grid-column: 2;
      }

      //border-right: 0;

      .content-container {
        width: 100%;

        .start & {
          padding-bottom: $gutter * 0.5;
          margin-bottom: $gutter * 0.5;
          border-bottom: 1px solid $color-border;
        }

        &::after {
          content: '';
          display: block;
          clear: both;
        }

        @media screen and (min-width: $m) {
          &.col-2 {
            column-count: 2;

            column-gap: $gutter;

            column-rule: 1px solid $grey_60;


            .container {
              display: block;
              width: 100%;
              // Umbruch zwingend nach dem Element, nie dazwischen.
              break-inside: avoid;
              break-after: always;
            }
          }
        }

        .container > *:first-child {
            margin-top: 0;
        }
      }
    }


  }
}

@media screen and (max-width: $s - 1) {
  .gtue-customer-teaser {
    padding: 0 20px 20px 20px;
  }


}

@media screen and (min-width: $s) {
  .container {
    width: 100%;

    .content {
      //grid-template-columns: $left_col_width_m auto $right_col_width_m;

      grid-template-columns: $left_col_width_m auto auto;

      .no-right-col & {
        grid-template-columns: $left_col_width_m auto;
      }
    }
  }

}

@media screen and (min-width: $m) {
  #gtue-main-wrapper #gtue-header-links.gtue-sticky {
    background: #fff url('./../images/logos/gtue-wide_2.png') top right no-repeat;
    background-size: contain;
  }

  .container {

    .content {
      grid-template-columns: $left_col_width_m auto $right_col_width_m;

      .no-right-col & {
        grid-template-columns: $left_col_width_m auto;
      }
    }
  }
}

@media (min-width: $xl){
  .container {

    .content {
      grid-template-columns: $left_col_width auto $right_col_width;

      .no-right-col & {
        grid-template-columns: $left_col_width auto;
      }
    }
  }
}

@media (min-width: $xxl){
  .container {
    width: $container_width_xxl;

    .content {
      grid-template-columns: $left_col_width auto $right_col_width;

      .no-right-col & {
        grid-template-columns: $left_col_width auto;
      }
    }
  }
}
