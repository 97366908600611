@import "font_roboto";
@import "gtue_iconfont"; // Achtung: .CSS, aber muss ohne endung sein!
@import "mixins";
@import "colors";
@import "distances";
@import "util";
@import "grid";
@import "links";
@import "navigation";
@import "sidebarnavigation";
@import "header";
@import "search";
@import "footer";
@import "sidebar";
@import "fonts";
@import "tables";
@import "media";
@import "video";
@import "paragraphs";
@import "simpleLightbox";
@import "customsimpleLightbox";
@import "simplescroller";
@import "accordion";
@import "panel";
@import "form";
@import "login";
@import "pagination";
@import "events";
@import "contentContainer";
@import "kba";
@import "tabs";
@import "print";
@import "auftraggeber_td";
@import "bueronummersuche";
@import "grosskundenliste";
@import "lizenzfreischaltung";
@import "werbemittelpakete";
@import "overlay";
@import "datatable";
@import "subpage";
@import "ablastungsrechner";
@import "reifenabrollumfang";
@import "sattelvormassrechner";
@import "achslastsvm";
@import "kbaFahrzeugdaten";
@import "checkify";
@import "wkz";
@import "bundjacken";
@import "partnernetz";
@import "eg_rubriken";
@import "themen";
@import "anlagensicherheit";
@import "recruiting_pakete";
@import "abrufschein";

html {
    scroll-padding-top: 5rem;
    overflow-x: hidden;
    overflow-y: scroll;
}

body {
    margin: 0;
    font-family: roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: calculateRem(-0.3px);

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    background: #FFFFFF;
    overflow-x: hidden;

    @media (max-width: $xxl) {
        padding: 0 calculateRem(30px);
    }

    @media (max-width: $m) {
        padding: calculateRem(10px);
    }

    @media (max-width: $m - 1) {
        padding: 0;
        min-height: 100vh;
    }

    * {
        box-sizing: border-box;
    }

    @media (min-width: $m) {
        &.start::before {
            background-color: $color-highlight;
        }
    }

}


span.date {
    display: block;
    font-weight: 500;
    @include font-size(14,18);
}

.content {
    padding-top: calculateRem(64px);

    @media (max-width: $s - 1) {
        padding: 0  calculateRem(10px) calculateRem(10px) calculateRem(10px);
    }

    hr {
        height: $hr_height;
        background-color: $brand_grey;
    }
    >.content-containers {

        padding: 0;

        >.content-container {
            display: block;

            .gallery img {
                max-width: $img_width;
            }

            .video-teaser video {
                max-width: $video_width;
            }
        }
    }

    .sidebar-left {

        #side-nav {
            position: static;
            background-color: $brand_grey;
            ul li > a {
                color: black;
                font-weight: 100;
                font-size: 1.0em;
                text-transform: none;
                letter-spacing: normal;
            }
        }
    }

    .sidebar {


         h4::after {
            content: "";
            height: $sidebar_underline_height;
            display: block;
            background-color: $grey_50;
        }
    }
}





