.login-wrapper {
  margin: 8vh auto;
  text-align: center;

  @media (max-width: $s) {
    margin-top: calculateRem(0px);
  }

  h1 {
    &::before {
      display: none;
    }
  }

  form {
    label {
      text-align: left;
      color: $grey_50;
      display: block;
      padding-left: calculateRem(8px);

      &.clickbox {
        text-align: center;
      }
    }

    .gridRow {
      margin-bottom: calculateRem(30px);
    }

    .form_error {
      margin: calculateRem(20px) 0 0 0;

      @media (max-width: $s) {
        margin-left: calculateRem(32px);
      }

      span {
        text-align: left;
        position: relative;
        top: 0;
        bottom: auto;
        @include font-size(14, 20);
        padding: 0;
      }

      &::before {
        right: auto;
        left: calculateRem(-35px);
        top: 0;
      }
    }
  }
}

#gtue-login {
  header {
    padding-top: calculateRem(27px);

    @media (max-width: $s) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .content {
      display: block;
  }

  .content-containers {
    overflow: visible;
  }

 #gtue-page-footer,.gtue-meta, #gtue-top-of-page > div, .hamburg {
    display: none !important;
  }

  #gtue-top-of-page{
    border-color: $color-highlight;
  }
}
