$nbsp: "\00a0";

.loader {
  background: url('./../images/loader.gif') no-repeat left top;
  width: 145px;
  height: 30px;
}

.clr {
  display: block;
  clear: both;
  height: 1px;
  float: none;
  width: 100%;
}

strong {
  font-weight: 500;
}

.border-bottom {
  border-bottom: 1px solid $color-border;
  padding-bottom: calculateRem(20px);
}

.scroll-wrapper {
  overflow: auto;
}

.content {
  .dark {
    background-color: $grey_80;
    color: white;
    font-weight: 400;

    h2,h3,h4, a {
      color: white;
      font-weight: 400;
    }
  }

  .light {
    background-color: $grey_20;
    color: $color-text;
    font-weight: 400;

    h2,h3,h4, a {
      color: $color-text;
      font-weight: 400;
    }
  }
}
