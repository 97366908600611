@use "sass:math";

fieldset {
	border: none;
	padding: 0;
	margin: 0;

	legend {
		padding: 0;
		margin: 0;
	}
}

.content form .gridCol label.small {
	display: inline;
	font-size: 14px;
	color: #555;
	line-height: 20px;
	font-weight: normal;
}

%clearfix {
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}

@mixin renderGrid($max) {
	@for $i from $max through 1 {
		&.gridWidth_#{$max} > .gridCol.gridWidth_#{$i} {
			width: math.div($i, $max) * 100%;
		}
	}
}

.sfg_box,
.kogis .main_article .sfg_box {
	h1, h2 {
		color: #545454;
	}
}

form.sfg_form, .content form, div.form-result {
	box-sizing: border-box;
	width: 100%;
	max-width: 780px;
	margin: 0 0 1em 0;

	/* damit der Outline beim Hover sichtbar ist.*/
	padding: 0 1px;

	//.gridCol {
	//  p:not(.form_error) {
	//    label:not(.clickbox, .delete) span {
	//      &:first-child {
	//        display: none;
	//      }
	//    }
	//  }
	//}

	label {
		width: 100%;
		font-weight: 500;
		margin-bottom: calculateRem(10px);
		@include font-size(14, 14);

		&.clickbox {
			display: block;
			font-weight: normal;

			@extend %clearfix;
		}

		&.radio.radioreset {
			display: inline-block;
			width: auto;

			span {
				display: inline;
			}

			input {
				display: none;
			}
		}

		&.delete {
			display: inline;
			font-weight: normal;

			input {
				float: none;
				display: inline-block;
				margin: 2px 5px 0 8px;
			}
		}
	}


	.delete {
		float: left;
		margin-right: 10px;
	}

	.fieldType_checkbox label, .fieldType_radio label, .fieldType_selectMulti label, .fieldType_file label {
		display: block;
	}

	span.result,
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="file"],
	input[type="date"],
	input[type="number"],
	input[type="password"],
	textarea,
	select {
		font-family: roboto, sans-serif;
		display: block;
		width: 100%;
		margin: 0;
		box-sizing: border-box;
		padding: calculateRem(9px);
		background: $grey_20;
		border: none;
		overflow: hidden;
		font-size: 1rem;
		line-height: 1.375rem;

		&:hover, &:focus {
			outline: 1px solid $color-highlight;
		}


		@include placeholder {
			@include font-size(14, 14);
		}

		&[disabled] {
			background: $grey_10;

			&:hover, &:focus {
				outline: none;
			}
		}
	}

	input:read-only {
		opacity: 0.8;

		&:hover, &:focus {
			outline: none;
		}

	}

	span.result {
		background: $grey_10;

		&.multiline {
			height: calculateRem(80px) !important;
		}


		&:hover, &:focus {
			outline: none;
		}
	}

	.fieldType_select,
	.fieldType_select_label {

		label > span:last-child {
			position: relative;
			display: block;
			pointer-events: none;

			&::before {
				content: '\e612';
				@include icon();
				position: absolute;
				top: 0.5rem;
				right: calculateRem(12px);
				line-height: 25px;
				font-size: 25px;
				color: $color-text;
				border-left: 1px solid $grey_40;
				margin: 0;
				padding: 0 calculateRem(5px);
				display: block;
			}
		}
	}

	.fieldType_select_label::before {
		top: 28px;
	}


	/* The switch - the box around the slider */
	label.switch {
		position: relative;
		display: inline-block;

		color: $grey_80;

		input {
			opacity: 0;
			width: 0;
			height: 0;

			&:checked + .slider {
				background-color: $color-highlight;
			}

			&:focus + .slider {
				box-shadow: 0 0 1px $color-highlight;
			}

			&:checked + .slider:before {
				transform: translateX(19px);
			}
		}

		/* The slider */
		.slider {
			position: relative;
			cursor: pointer;
			display: inline-block;
			top: calculateRem(5px);
			left: calculateRem(-5px);
			right: 0;
			bottom: 0;
			background-color: $grey_50;
			transition: .4s;
			width: calculateRem(40px);
			height: calculateRem(20px);

			&::before {
				position: absolute;
				content: "";
				height: calculateRem(16px);
				width: calculateRem(16px);
				left: calculateRem(2px);
				bottom: calculateRem(2px);
				background-color: white;
				transition: .4s;
			}

			&.round {
				border-radius: calculateRem(20px);

				&::before {
					border-radius: 50%;
				}
			}
		}
	}


	select {
		pointer-events: auto;
		appearance: none;
		border-radius: 0;
	}

	input[type="file"] {
		padding: calculateRem(5px);
	}

	select[multiple] {
		height: auto;

		option {
			padding: calculateRem(10px);
		}
	}

	textarea {
		height: 10em;
	}

	fieldset legend {
		padding: 0;
		margin: 0;
		font-weight: 500;
		margin-bottom: calculateRem(10px);

	}

	button, label.radioreset, input[type="submit"] {
		overflow: hidden;
		color: white;
		border: none;
		font-weight: 500;
		padding: calculateRem(13px) calculateRem(45px);
		border-radius: calculateRem(50px);
		border-color: transparent;
		background-color: $grey_60;
		box-shadow: none;
		outline: none;
		@include font-size(14, 14);
		cursor: pointer;
		@include transition(all, .3s);

		&:hover {
			background-color: $color-highlight;
			color: white;
		}
	}

	button[disabled] {
		cursor: not-allowed;
		color: #999999;
	}

	.gridRow {
		display: table;
		width: 100%;
		margin-bottom: 0;

		@media (min-width: $xs) {
			margin-bottom: calculateRem(40px);
		}

		&:last-child {
			margin-bottom: 0;
		}

		.gridRow {
			&:last-child {
				margin-bottom: 0;
			}
		}

		@media (min-width: $xs) {
			display: table;

			.gridCol {
				display: table-cell;
				vertical-align: top;
			}

			.gridCol + .gridCol {
				border-left: 0;
			}

			@include renderGrid(5);
			@include renderGrid(4);
			@include renderGrid(3);
			@include renderGrid(2);
		}

		.gridCol {
			min-height: 1em;
			box-sizing: border-box;
			margin-bottom: calculateRem(8px);

			@media (min-width: $xs) {
				padding: 0 calculateRem(10px);
				margin-bottom: 0;
			}

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}

			@extend %clearfix;

			@media screen and (max-width: $xs - 1) { // mobil leere Grids weg.
				& ~ .gridCol.fieldType_empty, &.fieldType_empty {
					display: none;
				}
			}

			p {
				margin: 0;
				padding: 0 0 1em 0;

				&:last-child {
					padding: 0;
				}
			}
		}

		&.helper {
			display: none;
		}
	}

	.gridRow .fieldType_fieldset.gridCol {
		border-left: 6px solid $grey_40;
		padding-left: calculateRem(25px);
	}


	.sfg_result {
		.gridCol {
			padding: 0 5px;

			p {
				padding: 0;
			}
		}
	}

	div.buttons {
		width: 100%;
		@extend %clearfix;

		div.next {
			float: right;
			width: math.div(1, 3)*100%;
			text-align: right;
		}

		div.prev {
			float: left;
			width: math.div(1, 3)*100%;
		}

		div.reset {
			float: right;
			width: math.div(1, 3)*100%;
			text-align: center;
		}
	}

	&.sfg_show_label {
		.gridCol label {
			display: block;
		}

		.gridRow {
			margin-bottom: 1rem;
		}

		.fieldType_select::before {
			top: 1.85rem;
			right: 0.25rem;
		}
	}

}


$errorColor: $color-highlight;
.form_error_text {
	display: none;
}

p.form_error {
	border: 0;
	background: none;
	position: relative;

	.fieldType_textarea & {
		padding-bottom: 0 !important;
	}


	&::before {
		@include icon();
		content: '\e653';
		position: absolute;
		right: calculateRem(5px);
		top: calculateRem(10px);
		color: $errorColor;
	}

	.form_error_text {
		display: inline;
	}

	& > label:first-child {
		display: flex;
		flex-direction: column-reverse;
		color: $errorColor;

		.fieldType_textarea & {
			top: auto;
			bottom: calculateRem(-35px);
		}

		@include font-size(14, 14);
		font-weight: normal;

		&.required::after {
			content: ' *';
		}

		& ~ input,
		& ~ select,
		& ~ textarea {
			clear: both;
			border-left: 3px solid $errorColor;

			&::before {
				content: '';
				display: block;
				clear: both;
			}

			&.eingabefeld_emailbot {
				display: none;
			}
		}

		& + div { // radio/checkbox nach Fehler etwas tiefer schieben.
			padding-top: 5px;
		}

		&.sfg_required,
		.required_field {
			display: none;
		}
	}
}

//Common Styles

.btn-std {
	right: 20px;
	overflow: hidden;
	color: $white;
	border: none;
	font-weight: 500;
	padding: calculateRem(13px) calculateRem(45px);
	border-radius: calculateRem(50px);
	border-color: transparent;
	background-color: $grey_60;
	box-shadow: none;
	outline: none;
	@include font-size(14, 14);
	cursor: pointer;
	@include transition(all, .3s);

	&:hover {
		background-color: $color-highlight;
		color: white;
	}
}

