#gtue-quick-search-wrapper {
    label.gtue-quick-search {
      display: none;
    }
}

.gtue-search-wrapper {
  //display: inline-block;
  //width: calculateRem(380px);

  width: 100%;
  display: table-cell;
  padding: 0 calculateRem(30px);
/*
  @media (max-width: $xxl) {
    width: calculateRem(250px);
  }

  @media (max-width: $xl) {
    width: calculateRem(200px);
  }

  @media (max-width: $l) {
    width: calculateRem(130px);
  }

  @media (max-width: $m) {
    //width: calculateRem(230px);
  }
*/

  //margin-right:calculateRem(50px);

  @media (max-width: $m - 1) {
    display: block;
    width: 100%;
    padding: 0;
  }

  form {
    background-color: $grey_20;
    padding: calculateRem(4px);
    max-width: calculateRem(330px);
    text-align: left;
    display: inline-block;
    //margin-right:calculateRem(50px);

    @media (max-width: $m - 1) {
      width: 100%;
      background-color: transparent;
      text-align: right;
      max-width: none;
      display: block;
    }

    input {
      border: none;
      background: transparent;
      height: calculateRem(30px);
      padding-left: calculateRem(5px);
      width: calc(100% - 43px);
      @include font-size(16,16);

      @media (max-width: $m - 1) {
        opacity: 0;
        width: calc(100% - 80px);

        .open-search & {
          opacity: 1;
          background-color: white;
        }
      }

      @media (max-width: $xs - 1) {
        width: calc(100% - 65px);
      }

      &:focus {
        outline: none;
      }
    }

    button {
      background-color: transparent;
      overflow: hidden;
      border: none;
      height: calculateRem(30px);
      width: 38px;
      color: $color-text;
      cursor: pointer;

      &:hover {
        background-color: transparent;
        color: $color-highlight;
        /**/
      }

      i {
        @include font-size(27,20);
        position: relative;
        top: calculateRem(2px);
      }
    }
  }
}

.content {
  form.search {
    margin-bottom: calculateRem(40px);

    .search-options {
      border-bottom: 4px solid $grey_20;
      font-weight: 500;
      margin-bottom: calculateRem(30px);


      legend {
        display: block;
        float: left;
        padding-right: 7%;
        padding-bottom: calculateRem(3px);
        margin-bottom: 0;
      }

      input {
        display: none;
      }

      input[type=radio]:checked + label {
        color: $color-highlight;
      }

      input[type=radio]:checked + label::after{
          content: '';
          display: block;
          height: 4px;
          background-color: $color-highlight;
          position: absolute;
          bottom: -4px;
          width: 100%;
          left: 0;
      }

      label {
        display: inline-block;
        padding: 0 7% calculateRem(5px) 7%;
        width: auto;
        margin-bottom: 0;

        cursor: pointer;
        position: relative;
      }
    }

    .flex-block {
      @media (min-width: $m) {
        display: flex;
      }
      @media (max-width: $m - 1) {
        display: block;
      }
      @media (max-width: $m - 1) {
        display: flex;
      }
      @media (max-width: $xs - 1) {
        display: block;
      }
    }

    .search-submit {
      background-color: $grey_20;
      display: block;
      height: calculateRem(35px);

      @media (min-width: $m) {
        width: 50%;
      }
      @media (max-width: $m - 1) {
        width: 100%;
      }
      @media (max-width: $m - 1) {
        width: 50%;
      }
      @media (max-width: $xs - 1) {
        width: 100%;
      }
    }

    .search-result-description {
      display: block;
      margin-bottom: 0;

      @media (min-width: $m) {
        margin-top: calculateRem(7px);
        padding-left: calculateRem(25px);
        width: 50%;
      }
      @media (max-width: $m - 1) {
        padding-left: 0;
        margin-top: calculateRem(20px);
        width: 100%;
      }
      @media (max-width: $m - 1) {
        margin-top: calculateRem(7px);
        padding-left: calculateRem(25px);
        width: 50%;
      }
      @media (max-width: $xs - 1) {
        padding-left: 0;
        margin-top: calculateRem(20px);
        width: 100%;
      }




      span {
        font-weight: 500;
      }
    }

    .search-query {
      background-color: transparent;
      padding: calculateRem(6px) calculateRem(4px);
      border: none;
      height: calculateRem(30px);
      display: inline-block;
      width: calc(100% - 43px);
      @include font-size(16,16);
    }

    button {
      background-color: transparent;
      padding: calculateRem(1px) calculateRem(7px);
      overflow: hidden;
      border: none;
      height: calculateRem(30px);
      width: 38px;
      color: $color-text;
      cursor: pointer;

      &:hover {
        background-color: transparent;
        color: $color-highlight;
      }

      i {
        @include font-size(24,20);
        position: relative;
        top: calculateRem(2px);
      }
    }
  }
}

.search-result-container {
  .search-result-item {
    padding: calculateRem(30px) 0;
    border-bottom: 1px solid $color-border;
    display: flex;

    @media (max-width: $xs - 1) {
      display: block;
    }


    .image {
      width: calculateRem(166px);
      min-width: 20%;
      margin-right: calculateRem(26px);
      display: block;

      @media (max-width: $xs - 1) {
        width: 100%;
        margin: 0 0 calculateRem(20px);
      }

      img {
        border: none;
      }
    }

    .text {
      width: 100%;
      h4 {
        a {
          text-decoration: none;
        }
        margin-top: calculateRem(-5px);
        margin-bottom: 0;
      }

      p {
        margin-top: 0;
      }

      .info {
        @include font-size(14,14);
        text-align: right;
        margin-bottom: 0;
      }
    }
  }
}
