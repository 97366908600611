.overlay-table {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.9);
  z-index: 1;

  @media (max-width: 767px){
    top: 1rem;
  }

  .overlay-inner {
    padding: 2rem;
    background: white;
    border: 1px solid $grey-50;

    @media (max-width: 767px){
      padding: 1rem;
    }
  }

  .close {
    //position: absolute;
    right: 20px;
    overflow: hidden;
    color: $white;
    border: none;
    font-weight: 500;
    padding: calculateRem(13px) calculateRem(45px);
    border-radius: calculateRem(50px);
    border-color: transparent;
    background-color: $grey_60;
    box-shadow: none;
    outline: none;
    @include font-size(14,14);
    cursor: pointer;
    @include transition(all,.3s);

    &:hover {
      background-color: $color-highlight;
      color: white;
    }
  }

  h3, h2 {
    margin-top: 0;
  }
}