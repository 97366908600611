#gtue-logo-holder {
  width: calculateRem($logo_width);
  @media (max-width: $xl) {
    width: calculateRem(300px);
  }

  @media (max-width: $m) {
    width: calculateRem(230px);
  }

  @media (max-width: $m - 1 ) {
    width: 100%;
    text-align: center;
    height: calculateRem(35px);
  }

  img {
    width: 100%;
    height: auto;

    @media (max-width: $m - 1) {
      width: auto;
      height: 100%;
    }
  }
}

header {
  padding: calculateRem(45px) 0 calculateRem(20px) 0;

  @media (max-width: $m - 1) {
    padding: calculateRem(10px) calculateRem(10px) 0 calculateRem(10px);
    border-bottom: 7px solid $color-highlight;
  }

  @media (min-width: $m) {
    height: calculateRem(106px);
  }

  .sticky &{
    @media (max-width: $m - 1) {
      position: fixed;
      width: 100%;
      top: calculateRem(-48px);
      background-color: white;
      z-index: 10;
      left: auto;
      right: auto;
    }
  }

  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .gtue-meta-wrapper {
    display: table;
    width: calc(100% - #{$logo_width});

    @media (max-width: $xl) {
      width: calc(100% - 300px);
    }

    @media (max-width: $m) {
      width: calc(100% - 230px);
    }

    @media (max-width: $m - 1) {
      width: 100%;
    }
  }

  .gtue-meta {
    text-align: right;
    display: table-row;

    @media (max-width: $m - 1) {
      width: 100%;
      margin-top: calculateRem(5px);
      //display: grid;
    }

    .gtue-search-wrapper {

      @media (max-width: $m - 1) {
        order: 1;
        position: relative;
        z-index: 1;
      }
    }


    .gtue-meta-salutation {
      margin-right: calculateRem(30px);
      color: $grey_60;

      width: auto;
      display: table-cell;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /* display: inline-block; */
      padding: 0 20px;
      vertical-align: middle;

      /*
      @media (max-width: $l) {
        white-space: nowrap;
        max-width: calculateRem(180px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
      */


      @media (max-width: $m - 1) {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: calculateRem(10px);
        border-right: none;
        position: absolute;
        top: calculateRem(58px);
      }

      @media (max-width: $xs - 1) {
        text-align: left;
        padding-left: calculateRem(31px);
        font-size: calculateRem(14px);
        letter-spacing: -.7px;
      }
    }


    .gtue-login {
      //display: inline-block;
      display: table-cell;
      width: auto;
      white-space: nowrap;
      vertical-align: middle;

      @media (max-width: $m - 1) {
        //display: none;
        width: calculateRem(75px);
        order: 0
      }

      @media (max-width: $xs - 1) {
        width: calculateRem(55px);
      }



      i{
        @include font-size(23,20);
        position: relative;
        top: calculateRem(2px);
        &::before {
          margin: 0;
        }
      }


      > a {
        margin-right: calculateRem(17px);



        @media (max-width: $m - 1) {
          width: calculateRem(25px);
          overflow: hidden;
          display: inline-block;
          height: calculateRem(25px);
          margin-right: 0;
          margin-left: calculateRem(10px);

          .open-mobile & {
            //position: absolute;
            top: calculateRem(70px);
            //right: 0;
            z-index: 100;
            display: inline-block;

            &.gtue-meta-kontakt {
              //right: 90px;
            }
          }
        }

        @media (max-width: $xs - 1) {
          margin-left: 0;
        }

      }
    }


  }
}
