.dataTables_wrapper {

  table.dataTable {
    margin-bottom: .5rem;

    &.no-footer {
      border-bottom: 1px solid $grey_50;
    }

    thead th {
      text-align: left;
      padding: 8px 18px 8px 10px;
      font-size: 14px;
      background-color: $grey_40;
      color: $color-text;
      font-weight: 500;
      border-bottom: 1px solid $grey_50;
      border-top: 1px solid $grey_50;
      background-image: none;
      position: relative;

      &:after,
      &:before {
        font-family: "gtue_iconfont" !important;
        font-weight: 900;
        color: $brand_red;
        position: absolute;
        right: 5px;
        font-size: 14px;

        @media(max-width: 1070px){
          font-size: 12px;
        }
      }

      &.sorting_desc {
        &:after {
          content: "\e612";
          bottom: 4px;
        }
      }

      &.sorting_asc {
        &:after {
          content: "\e610";
          top: 4px;
        }
      }

      &.sorting {
        &:after {
          content: "\e612";
          bottom: 4px;
        }

        &:before {
          content: "\e610";
          top: 4px;
        }
      }
    }

    td.details-control {
      background: url('/assets/images/icons/add_rot.png') no-repeat center center;
      background-size: 20px;
      cursor: pointer;
    }

    p {
      margin: 0;
    }

    table {
      padding-left: 19px;
      border-bottom: 0;
      margin-bottom: 0;

      td:first-child {
        width: 120px;

        @media(max-width: 500px){
          width: 100px;
        }
      }

      td, tr, tbody {
        background: $white;
      }

      td:hover,
      tbody:hover {
        background: $white;
      }
    }

    tr.shown+tr td:hover {
      background: $white;
    }


  }

  .dataTables_info,
  .dataTables_paginate {
    font-size: 14px;
  }

  .dataTables_paginate {
    .paginate_button {
      border-radius: 0;

      &.current {
        background: none;
        background-color: $grey_20;
      }

      &:hover {
        background: none;
        background-color: $brand-red;
      }
    }
  }
}