.eg_rubriken {

  img {
    border: 0;
  }

  .interactive-image {
    width: 100%;
    height: 0;
    padding-bottom: 70%;
    background-image: url('/assets/images/ALD-Flyer_Einkaufsgemeinschaft_2021-08.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: left 0 bottom 65%;
    position: relative;
    opacity: 0;
    margin-top: 2rem;
    margin-bottom: 4rem;

    @media(max-width: 1280px) {
      margin-top: 5em;
    }

    @media(max-width: 720px) {
      margin-bottom: 6em;
    }

    @media(max-width: 560px) {
      margin-bottom: 0;
      padding-bottom: 60%;
      margin-top: 1rem;
    }

    .bubble {
      position: absolute;
      max-width: 12em;
      background-color: #da1f3d;
      color: #fff;
      text-align: center;
      opacity: 0;
      transition: background 0.2s ease-in;

      &.active {
        background-color: #A31830;
      }

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        left: 1.5em;
        border: .75rem solid transparent;
        transition: all 0.2s ease-in;
      }

      @media(max-width: 1060px) {
        font-size: 14px;
      }

      @media(max-width: 560px) {
        display: none;
      }

      a {
        color: #fff !important;
        text-decoration: none;
        font-weight: 500;
        padding: 0.75em 0.85em;
        display: inline-block;
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 10%;
          height: 0;
          border-bottom: 2px solid #fff;
          position: relative;
          margin: 0 auto;
          top: 1px;
          opacity: 0;
          transition: all 0.3s ease-in;
        }
      }

      &.active > a:after,
      &.active li.link-active a:after {
        opacity: 1;
        width: 100%;
      }

      h3 {
        font-size: 1em;
        color: #fff;
        padding: 0.75em 0.85em 0;
        margin-top: 0;
      }

      ul {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;
        margin-top: 0.5em;

        li {
          padding-left: 0;
          list-style-type: none;

          &:last-child a {
            padding-top: 0;
            padding-bottom: 0.75em;
            color: #000;
          }

          a {
            padding-top: 0;
            padding-bottom: 0.18em;
          }

          &:before {
            font-family: "gtue_iconfont" !important;
            content: '\e68f';
            font-size: 1rem;
            position: relative;
            left: 10px;
            pointer-events: none;
          }
        }
      }
    }


    .bubble-1 {
      left: 60%;
      bottom: 80.5%;
    }

    .bubble-1:before {
      border-bottom: none;
      border-top-color: #da1f3d;
      top: 100%;
    }

    .bubble-2 {
      left: 45%;
      bottom: 64%;
    }

    .bubble-2:before {
      left: 2.5em;
      border-bottom: none;
      border-top-color: #da1f3d;
      top: 100%;
    }

    .bubble-3 {
      left: 85%;
      bottom: 44%;
    }

    .bubble-3:before {
      border-bottom: none;
      border-top-color: #da1f3d;
      top: 100%;
    }

    @media(max-width: 1060px) {
      .bubble-3 {
        left: 82%;
      }
    }

    .bubble-4 {
      left: 72%;
      top: 72%;
    }

    .bubble-4:before {
      border-top: none;
      border-bottom-color: #da1f3d;
      bottom: 100%;
    }

    @media(max-width: 1060px) {
      .bubble-4 {
        left: 73%;
      }
    }


    .bubble-5 {
      left: 57.5%;
      top: 74.7%;
    }

    .bubble-5:before {
      border-top: none;
      border-bottom-color: #da1f3d;
      bottom: 100%;
    }

    @media(max-width: 1060px) {
      .bubble-5 {
        left: 54.5%;
      }
    }

    .bubble-6 {
      right: 47%;
      top: 75%;
    }

    .bubble-6:before {
      left: 6em;
      border-top: none;
      border-bottom-color: #da1f3d;
      bottom: 100%;
    }

    .bubble-7 {
      left: 29%;
      bottom: 41%;
    }

    .bubble-7:before {
      left: 2.5em;
      border-bottom: none;
      border-top-color: #da1f3d;
      top: 100%;
    }

    .bubble-8 {
      left: 31%;
      bottom: 65%;
    }

    .bubble-8:before {
      left: 2.5em;
      border-bottom: none;
      border-top-color: #da1f3d;
      top: 100%;
    }

    @media(max-width: 1060px) {
      .bubble-8 {
        left: 25%;
      }
    }

    .bubble-9 {
      left: 20%;
      bottom: 88%;
    }

    .bubble-9:before {
      left: 2.5em;
      border-bottom: none;
      border-top-color: #da1f3d;
      top: 100%;
    }

    .bubble-10 {
      left: 8%;
      top: 82%;
    }

    .bubble-10:before {
      left: 4.5em;
      border-top: none;
      border-bottom-color: #da1f3d;
      bottom: 100%;
    }

    @media(max-width: 1060px) {
      .bubble-10 {
        left: 2%;
        top: 83%;
      }
    }


    .bubble-down.active::before {
      border-top-color: #A31830;
    }

    .bubble-up.active::before {
      border-bottom-color: #A31830;
    }
  }
}

.ek_nav {
  display:none;

  @media(max-width: 560px){
    display: block;
  }
}
