.sidebar-containers {
  .content-container {
    margin-bottom: calculateRem(30px);
    @include font-size(14,18);

    h4 {
      margin-bottom: calculateRem(15px);
    }

    @media screen and (min-width: $m) {
      &:first-child {
        h3 {
          margin-top: 0;
        }
      }
    }

    span.subheadline {
      @include font-size(16,20);
      display: block;
      font-weight: 500;
      margin-bottom: calculateRem(5px);
    }

    &:last-child {
      border-bottom:none;
    }

    .link-container {
      padding-bottom: calculateRem(30px);
      border-bottom: 1px solid $color-border;

      & ~ .link-container {
        margin-top: calculateRem(30px);
      }

      img {
        margin-bottom: calculateRem(7px);
        width: calculateRem(50px);
        border: none;
      }

      h3 {
        margin-bottom: calculateRem(5px);
        @include font-size(20,25);
        border-bottom: none;
      }

      a {
        display: block;
        text-decoration: none;
        color: $grey_60;

        &:hover, &:focus, &:active {
          color: $color-highlight;

          img {
            filter: drop-shadow(0 0 2px $color-highlight);
          }

          h3 {
            color: inherit;
          }
        }

        h3 {
          color: $color-text;
        }

        & > span {
          text-decoration: underline;
        }
      }
    }

    .blackboard-container,
    .rundschreiben-container {
      ul {
        margin: 0;
        padding: 0;
        display: block;
        list-style-type: none;

        li {

          h4 {
            margin: 0;
            padding: 0;
            font-size: 1.2em;

            & + p {
              margin-top: 0.3125rem;
            }
          }
        }
      }
    }

    .rundschreiben-container {
      ul {
        li {
          // Margin bottom von p halbieren, um mittig die linie zu ziehen.
          margin-bottom: (1.25rem * 0.5);
          border-bottom: 1px solid black;

          p:last-child {
            // margin bottom von li wieder addieren, um die Mitte genau zu positionieren.
            margin-bottom: 0.3125rem + (1.25rem * 0.5);
          }
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }

    figure {
      margin: 0;
    }
  }
}
