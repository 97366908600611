#reifenabrollumfang {

  display: none;

  #rau-ergebnis,
  #rau-validation {

    border-top: 2px solid $grey_40;
    border-bottom: 2px solid $grey_40;


    h2 {
      font-size: 20px;
    }

    h3 {
      margin-top: 0;
      padding-top: 1.2rem;
    }

    p {
      line-height: 1.8em;
      padding-top: 1.1rem;
    }
  }
}

.content-container .intro {
  text-align: center;
  border-top: 2px solid $grey_40;
  border-bottom: 2px solid $grey_40;
  padding-top: 2rem;
  padding-bottom: 1.8rem;
}

.content-container .intro a {
  background-color: $grey_40;
  background-repeat: no-repeat;
  background-position: 55px -30px;
  background-size: 59%;
  display: inline-block;
  padding: 45px;
  margin-right: 1rem;
  color: $black;
  text-decoration: none;
  width: 240px;

  @media (max-width:1030px){
    width: 140px;
    padding: 30px;
    background-position: 32px -15px;

  }

  &.pkw {
    background-image: url(./../images/pkw.png);
    background-position: 50px -35px;

    @media (max-width: 1030px) {
      background-position: 32px -18px;
    }

  }

  &.motorrad {
    background-image: url(./../images/motorrad.png);
  }

  &:hover,
  &.active {
    background-color: $brand_red;
  }
}

.tab-nav a {
  background-color: $grey_40;
  background-repeat: no-repeat;
  background-position: 5px center;
  background-size: 40px;
  display: inline-block;
  padding: 15px 20px;
  margin-right: 10px;
  color: $black;
  text-decoration: none;

  &.active,
  &:hover {
    background-color: $brand_red;
    color: $black;
  }

  &.pkw {
    background-image: url(./../images/pkw.png);
    padding-left: 52px;
    background-size: 40px;
    background-position: 7px 0;
  }

  &.motorrad {
    background-image: url(./../images/motorrad.png);
    padding-left: 45px
  }
}

#krad-abrollumfang {

    display: none;

    #krad-diff {
      border: 1px solid $grey_40;
      padding: 1rem;
    }

    i {
      color: $brand-red;
      display: inline-block;
      position: relative;
      top: -3px;
      left: 3px;
      font-size: 16px;
      cursor: pointer;

      &:hover,
      &:active {
        &+.tooltip {
          display: block;
        }
      }
    }

    .gridWidth_7 .gridCol {
      width: 14%;

      &.fieldType_text {
        font-size: 12px;
        font-weight: 500;
        text-align: right;

        @media (max-width: 450px) {
          text-align: left;
          font-size: 14px;
        }
      }

      &.fieldType_header {
        text-align: left;
        font-size: 14px;
      }

      @media (max-width: 449px) {
        width: 100%;
      }
    }

    .tooltip {
      position: absolute;
      top: -35px;
      left: 55px;
      width: 228px;
      background: #fff;
      padding: 5px;
      border: 1px solid $grey_40;
      z-index: 99;
      display: none;

      &:hover,
      &:active {
        display: block;
      }
    }

    .no_label label {
      display: none !important;
    }

    input[disabled] {
      font-weight: 500;
    }


    .gridCol {
      position: relative;
    }

    .checkify__message {
      width: 180px;
      line-height: 1.2em !important;
      padding: 5px 12px !important;

    }

    .fieldType_header {
      font-size: 14px;
      width: 18%;
      position: relative;
    }

    form.sfg_form select.zoll {
      height: 2.45rem;
    }

    select.active {
      border: 1px solid $brand_red;
    }

    form.sfg_form.sfg_show_label .krad-calc .fieldType_select::before {
      right: 0.55rem;
      top: 2rem;
    }

    @media (min-width: 450px) {

      form.sfg_form.sfg_show_label .no_label + .fieldType_select::before {
        top: 0.5rem;
      }
    }


    @media (max-width: 840px) {

      form.sfg_form.sfg_show_label .gridCol label, .content form.sfg_show_label{
        font-size: 14px;
      }

      form.sfg_form .gridRow .gridCol,
      .content form .gridRow .gridCol {
        padding: 0 0.25rem;
      }
    }

    @media (min-width: 450px){
      form.sfg_form .gridRow.gridWidth_6 > .gridCol.gridWidth_1.fieldType_select {
        width: 20%;
      }
    }

    @media (min-width: 450px){
      form.sfg_form .gridRow.gridWidth_6 > .gridCol.gridWidth_1.field_diff {
        width: 14%;
      }
    }


    @media (min-width: 450px){
      form.sfg_form .gridRow.gridWidth_6 > .gridCol.gridWidth_1.field_15 {
        width: 15%;
      }
    }

    @media (min-width: 450px){
      form.sfg_form .gridRow.gridWidth_6 > .gridCol.gridWidth_1.field_10 {
        width: 10%;
      }
    }

    @media (min-width: 450px){
      form.sfg_form .gridRow.gridWidth_6 > .gridCol.gridWidth_1, .content form .gridRow.gridWidth_6 > .gridCol
      .gridWidth_1, div.form-result .gridRow.gridWidth_6 > .gridCol.gridWidth_1 {
        width: 18%;
        padding-right: 0;
      }
    }


    @media(max-width: 449px){
      .no_label label {
        display: block !important;
      }

      .field_diff .no_label label {
        display: none !important;
      }

      .fieldType_header {
        margin-bottom: 1em  !important;
        width: 100%;
        padding-left: 3px !important;
      }
    }

    @media(max-width: 1300px){

      label span,
      .fieldType_header span {
        display: none;
      }

    }
  }
