/*-------------------
Functions START
---------------------*/
@use "sass:math";

@mixin font-size($sizeValue,$lineheight) {
  font-size: ($sizeValue) + px;
  font-size: math.div($sizeValue, 16) + rem;
  line-height: ($lineheight) + px;
  line-height: math.div($lineheight, 16) + rem;
}

@mixin letter-spacing($sizeValue) {
  letter-spacing: ($sizeValue) + px;
  letter-spacing: math.div($sizeValue, 16) + rem;
}

@mixin transition($style,$time){
  -o-transition: $style ease-out $time;
  transition: $style ease-out $time;
}

@mixin transform($style) {
  -o-transform: $style;
  transform: $style;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}


@mixin icon(){
  /*
  font-family: 'gtue-iconfont'
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.7em;
  line-height: 1em;
*/
  font-family: "gtue_iconfont"!important;
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  font-size: 120%;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



@function calculateRem($size) {
  $remSize: math.div($size, 16px);
  @return #{$remSize}rem;
}

/*--- Functions END */
