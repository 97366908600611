.pn-table-wrapper {

  position:relative;
  min-height: 700px;
  margin-top: 2rem;

  .leaflet-popup-content-wrapper {
    border-radius: 0;
  }

  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-color: #9b9b9b;
  }

  .detail-control {
    font-size: 1.5em;
    color: #d51d40;
    cursor: pointer;
  }

  .overlay,
  .overlay-kontakt {
    padding: 20px;
    background: #fff;
    border: 2px solid #ccc;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
  }

  .pn-karte-infos {
    background: #fff;
    padding: 0;
    text-align: left;
    width: 40%;
    float: left;

    p {
      font-size: 14px;
      line-height: 1.5em;
    }
  }

  .paginate_button.disabled {
    border: 0 !important;
  }

  .close {
    color: #fff;
    border: none;
    font-weight: 500;
    padding: 13px 45px;
    border-radius: 50px;
    border-color: transparent;
    background-color: #888;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    font-size: 14px;

  }

  .close:hover {
    background-color: #d51d40;
    color: white;
  }

  .submit-btn>div {
    display: inline-block;
    margin-right: 10px;
  }

  .overlay
  {
    height: 630px;
  }

  .overlay-kontakt {
    height: auto;
  }

  .overlay-kontakt .msg p {
    padding: 20px !important;
    border: 1px solid #ccc;
  }

  .overlay img {
    border: 0;
  }

  .dataTables_wrapper .dataTables_scroll {
    padding-top: 1rem;
    margin-bottom: .5rem;
  }

  .dataTables_wrapper table.dataTable {
    margin-bottom: 0;
  }

  .dataTables_wrapper .dataTables_filter input,
  .dataTables_wrapper .dataTables_length select {
    background: #f2f2f2;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C2C4C6;
    margin-left: 10px;
    outline: none !important;
    padding: 5px;
  }

  .dataTables_wrapper .dataTables_length select {
    margin: 0 5px;
  }


  .dataTables_wrapper .dataTables_filter label,
  .dataTables_wrapper .dataTables_length label,
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate
  {
    font-weight: 500;
    font-size: 14px;
  }


  .dataTables_wrapper .dataTables_paginate .paginate_button.current,
  .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    border-radius:0;
    background: #f9f9f9;
  }

  .dataTables_wrapper table.dataTable thead th:after,
  .dataTables_wrapper table.dataTable thead th:before {
    font-family: "gtue_iconfont" !important;
    font-size: 14px;
    content:"";
  }

  .dataTables_wrapper table.dataTable thead th {
    padding: 8px 22px 8px 10px;
  }

  .dataTables_wrapper .dataTables_scrollHead table.dataTable thead th.sorting_desc:after {
    content: '\E610';
    top: 4px;
  }

  .dataTables_wrapper .dataTables_scrollHead table.dataTable thead th.sorting_asc:after {
    content: '\E612';
    bottom: 4px;
  }

  .dataTables_wrapper .dataTables_scrollHead table.dataTable thead th.sorting:after {
    content: '\E610';
    top: 4px;
  }

  .dataTables_wrapper .dataTables_scrollHead table.dataTable thead th.sorting:before {
    content: '\E612';
    bottom: 4px;
  }

  #pn-table table {
    padding-left: 0;
  }

  #pn-karte {
    height: 445px;
    width: 100%;

    @media(max-width: 520px) {
      height: 415px;
    }
  }

  fieldset {
    width: 60%;
    float: right;
    text-align: right;
  }

  #route-single-slot-form {
    max-width: 100%;
  }

  #berechnen {
    padding: 0.8125rem 1.2rem !important;
  }

}