.pagination {
  &.top {
    border-bottom: 1px solid $color-border;
  }


  padding: calculateRem(5px) 0;
  clear: both;
  float: none;
  display: flex;
  justify-content : space-between;

  .results {
    font-weight: 500;
    @include font-size(16,30);
    display: block;
    width: 100%;
  }

  ul {
    text-align: right;
    margin: 0;
    display: block;
    width: 100%;

    li {
      display: inline-block;
      margin-left: calculateRem(5px);

      &.prev,&.next,&.first,&.last {
        margin: 0;
        a {
          @include font-size(0,0);
          color: transparent;
          width: calculateRem(20px);
          background-color: transparent;
          position: relative;
          &::after{
            height: calculateRem(15px);
            color: $color-text;
            @include icon();
            content: '\e611';
            @include font-size(20,15);
            position: relative;
            top: calculateRem(3px);
          }

          &:hover,&:focus {
            &::after {
              color: $color-highlight;
            }
            &::before {
              background-color: $color-highlight;
            }
          }
        }
      }

      /*
      &.first a::before, &.last a::before {
        content: '';
        height: calculateRem(14px);
        width: calculateRem(2px);
        display: block;
        position: absolute;
        background-color: $color-text;
        left: calculateRem(9px);
        top: calculateRem(6px);
      }

      &.last a::before {
        left: auto;
        right: 0;
      }
*/

      &.first {
        a {
          &::after {
            border-left: 2px solid $color-text;
          }
        }
      }

      &.next,&.last {
        a {
          &::after{
            @include icon();
            @include font-size(20,15);
            content: '\e60f';
          }
        }
      }

      &.last {
        a {
          &::after {
            border-right: 2px solid $color-text;
          }
        }
      }

      a {
        display: block;
        width: calculateRem(30px);
        height: calculateRem(30px);
        background-color: $grey_20;
        @include font-size(16,30);
        text-align: center;
        text-decoration: none;
      }

      &.active {
        a {
          color: $color-highlight;
        }
      }
    }
  }

}
