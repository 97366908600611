@font-face {
	font-family: "roboto";
	src: url("../font/roboto/roboto300.woff") format("woff");
	font-display: auto;
	font-style: normal;
	font-weight: 300;
	font-stretch: normal;
}

@font-face {
	font-family: "roboto";
	src: url("../font/roboto/roboto500.woff") format("woff");
	font-display: auto;
	font-style: normal;
	font-weight: 500;
	font-stretch: normal;
}

@font-face {
	font-family: "roboto";
	src: url("../font/roboto/roboto400.woff") format("woff");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

.tk-roboto {
	font-family: "roboto", sans-serif;
}
