

$brand_red: #da1f3d;
$brand_grey: #c2c4c6;
$brand_green: #8398A7;

$grey_10: rgba(242, 242, 242, 0.6);
$grey_20: #f2f2f2;
$grey_40: #dadbdd;
$grey_50: #9b9b9b;
$grey_60: #555555;
$grey_80: #323234;

$notification_success: #689f38;
$notification_warning: #ee8B00;
$notification_error: #c03344;
$notification_mark: #168ad5;

$white: #fff;
$black: #000;

$color-text: #323234;
$color-highlight: $brand_red;
$color-border: $grey_60;
$color-icons: $grey_60;

.danger {
  color: $brand_red;
}