/*-------------------
Hauptnavigation START
---------------------*/
nav#gtue-top-of-page {
  background-color: transparent;
  border-bottom: 5px solid $brand_grey;
  position: absolute;
  z-index: 10;
  width: 100vw;
  left: 0;

  & > div {
    width: calc(100% - 60px);
    margin: auto;

    @media (min-width: $xxl) {
      width: 1350px;
    }
  }

  @media (max-width: $m - 1) {
    border-bottom: 0;
    height: 1px;

  }

  .sticky &{
    position: fixed;
    top: 0;
    background-color: white;

    @media (max-width: $m - 1) {
      width: calc( 100% - 20px );
    }

    @media (max-width: $m - 1) {
      width: 100%;
      margin-top: 49px;
    }
  }

  .start & {
    border-color: $color-highlight;
  }

  margin-bottom: calculateRem(20px);

  > div {


    @media (max-width: $m - 1) {
      display: none;
      top: calculateRem(-6px);
      background-color: $grey_20;
      border-bottom: none;

      //height: calc( 100vh - 70px);

      height: calc(100vh - 57px);
      overflow: auto;
      width: 100%;

      /*
      &::before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 calculateRem(6px) calculateRem(6px) calculateRem(6px);
        border-color: transparent transparent $color-highlight transparent;
        position: absolute;
        top: calculateRem(-13px);
        right: calculateRem(8px);
      }
      */

    }

    ul {
      list-style-type: none;
    }

    > ul {

      &.gtue-main-navi {
        background-color: white;
        margin-top: 0;
        border-top: none;
        @media (max-width: $m - 1) {
          padding: 0 calculateRem(10px) calculateRem(10px) calculateRem(10px);
        }
      }

      margin-top: calculateRem(6px);
      margin-bottom: 0;
      padding: 0;
      position: relative;

      > li {
        @media (max-width: $m - 1) {
          display: block;
          //border-bottom: 1px solid $grey_40;
          position: relative;

          &:first-child {
            border-top: 1px solid $grey_40;
          }
        }

        div {
          display: none;
          padding-left: 0;
          overflow: hidden;
          margin-top: calculateRem(11px);




          @media (max-width: $m - 1) {
            border-top: 2px solid $grey_40;
            border-bottom: 2px solid $grey_40;

            ul {
              padding-left: calculateRem(15px);
            }
          }

          > ul {
            position: relative;
            @media (min-width: $m) {
              padding-left: 0;
            }

            ul {
              display: none;
              list-style-type: none;

              @media (max-width: $m - 1) {
                border-top: 2px solid $grey_40;
                border-bottom: 2px solid $grey_40;

                li:last-child > ul:last-child {
                  border-bottom: none;
                }
              }


              a {
                @include font-size(16, 18);
                font-weight: normal;
              }
            }

            @media (max-width: $m - 1) {
              //border-bottom: 1px solid $grey_40;
              //padding-bottom: calculateRem(10px);
              //margin-bottom: calculateRem(10px);

              &:last-child {
                border-bottom: none;
              }
            }

            li {
              position: relative;

              &.headline {
                @media (max-width: $m - 1) {
                  display: none;
                  @include font-size(20, 24);
                }
              }
            }
          }

          @media (min-width: $m) {
            height: 0;
            display: block;
            position: absolute;
            //border-bottom: 8px solid $color-highlight;
            width: 100%;
            left: 0;
            top: calculateRem(38px);
            background-color: white;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
            z-index: 1;
            padding: 0;
            @include transition(opacity,.2s);


            opacity: 0;


            column-count: 4;

            column-gap: calculateRem(48px);

            column-fill: auto;

            > ul {
              /* for Firefox */
              page-break-inside: avoid;
              /* for the rest */
              break-before: column;

              &::before {
                content: '';
                width: 1px;
                height: calculateRem(200px);
                top: 0;
                background-color: $grey_40;
                position: absolute;
                left: calculateRem(-24px);
              }

              &:first-child {
                /* for Firefox */
                page-break-inside: auto;
                /* for the rest */
                break-before: auto;

                &::before {
                  display: none;
                }
              }

              > li {
                display: inline-block; // Spaltenumbruch innerhalb von Unterpunkten verhindern
                width: 100%;
                margin-bottom: calculateRem(8px);

                /*
                &.seperator::after {
                  content: '';
                  width:1px;
                  height: calc( 100% - 48px );
                  top: calculateRem(24px);
                  background-color:$grey_40;
                  position: absolute;
                }
                */

                &.headline {
                  //break-before: column;
                  color: $grey_60;
                  font-weight: 400;
                  margin-bottom: calculateRem(10px);
                }

                // Webkit Fix für sauberen Umbruch zwischen Unterpunkten - Bug? normalerweise geht das.
                @media screen and (-webkit-min-device-pixel-ratio: 0) {
                  display: block;
                }

                ul li {
                  float: none;
                  width: auto;
                }
              }
            }

          }
          list-style-type: none;
        }

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        list-style-type: none;
        display: inline-block;


        input[type=radio] {
          display: none;
        }

        label {
          display: none;
        }

        @media (max-width: $m - 1) {
          input[type=radio] {
            /* hide native checkbox */
            position: absolute;
            opacity: 0;

            &:focus + label {
              color: $brand_red;
            }
          }


          ul label {
            height: calculateRem(35px);

            &::after {
              @include font-size(34, 34);
            }
          }

          input[type=radio]:checked + label::after {
            /* show open folder icon if item is checked */
            content: '\e610';
          }

          input[type=radio]:checked + label + div,
          input[type=radio]:checked + label + ul,
          input[type=radio]:checked + label:nth-of-type(n) + div,
          input[type=radio]:checked + label:nth-of-type(n) + ul {
            /* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
            /* show children when item is checked */
            display: block;
          }
        }


        > a {
          position: relative;
          padding: calculateRem(11px) calculateRem(12px);
          @include font-size(19, 22);

          @media (max-width: $xl - 1) {
            padding: calculateRem(11px) calculateRem(5px);
            @include font-size(18, 22);
          }

          @media (max-width: $l - 1) {
            @include font-size(16, 22);
          }



          display: block;

          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            height: 5px;
            bottom: calculateRem(-5px);

            @media (max-width: $m - 1) {
              display: none;
            }
          }

        }

        a {
          @media (max-width: $m - 1) {
            padding: calculateRem(14px) calculateRem(18px) calculateRem(14px) 0;
            @include font-size(24, 28);
            display: block;
          }
        }

        &.active > a, &.active-path > a {
          color: $color-highlight;

          &::after {
            background-color: $color-highlight;
          }
        }


        @media (min-width: $m) {
          &:first-child {
            > a {
              padding-left: 0;
            }
          }

          &:last-child {
            > a {
              //padding-right: 0;
            }
          }
        }

        @media (min-width: $m) {
          &.has-children {

          }

          &.has-children:hover,
          &.has-children.hover {
            > a {
              color: $color-highlight;
             }


            > a:hover ~ ul,
            > a:hover ~ div,
            > a:focus ~ ul,
            > a:focus ~ div,
            > ul,
            > div {
                @media (min-width: $m) {
                  display: block;
                  opacity: 1;
                  @include transition(opacity,.2s);
                  -o-transition-delay:.3s;
                  transition-delay:.3s;
                  padding: calculateRem(24px);
                  height: calculateRem(250px);
                }
            }

            > ul, > div {
              a {
                @include font-size(16, 24);

                &:hover, &:focus {
                  color: $color-highlight;
                }
              }


              &:last-child {
                border-bottom: none;
              }

              p {
                margin: 0;
              }
            }
          }
        }
      }

      @media (max-width: $m - 1) {
        li.has-children > label {
          border-left: 1px solid $grey_40;
          position: absolute;
          cursor: pointer;
          right: 0;
          top: calculateRem(6px);
          width: calculateRem(50px);
          height: calculateRem(44px);
          display: block;

          &::after {
            display: block;
            width: 100%;
            height: 100%;
            @include icon();
            content: '\e612';
            @include font-size(34, 50);

            text-align: center;
            left: 0;
            position: relative;
          }
        }
      }
    }
  }
}

.home-btn {
  display: none;

  @media screen and (max-width: $m - 1) {
    display: block;
  }
}
/*--- Hauptnavigation END */

/*-------------------
Mobile START
---------------------*/
.open-mobile {
  overflow: hidden;
  body {
    overflow: hidden;
  }
}


#gtue-top-of-page {
  label.hamburg {
    display: none;
    background: transparent;
    width: calculateRem(47px);
    height: calculateRem(43px);
    margin-left: auto;
    margin-right: auto;
    border-radius: 0px;
    cursor: pointer;

    position: absolute;
    top: calculateRem(4px);
    right: 0;

    @media screen and (max-width: $m - 1) {
      display: block;
      top: calculateRem(-45px);
      left: 0;
      right: auto;
      z-index: 1;

      .sticky &{
        top: calculateRem(-49px);
      }
    }

    @media screen and (max-width: $s - 1) {
      //top: calculateRem(-84px);
    }
  }

  input#hamburg {
    display: none
  }

  .line {
    position: absolute;
    left: 10px;
    height: 3px;
    width: 22px;
    background: $color-border;
    display: block;
    transition: 0.5s;
    transform-origin: center;
  }

  .line:nth-child(1) {
    top: 12px;
  }

  .line:nth-child(2) {
    top: 18px;
  }

  .line:nth-child(3) {
    top: 24px;
  }

  #hamburg:checked + .hamburg .line:nth-child(1) {
    transform: translateY(8px) rotate(-45deg);
    background: $color-highlight;
  }

  #hamburg:checked + .hamburg .line:nth-child(2) {
    opacity: 0;
  }

  #hamburg:checked + .hamburg .line:nth-child(3) {
    transform: translateY(-4px) rotate(45deg);
    background: $color-highlight;
  }

  #hamburg:checked + label {
    top: calculateRem(-50px);
  }

  #hamburg:checked + label + div{
    display: block;
    z-index: 10;
    padding-top: 0;
  }
}

nav#gtue-top-of-page {
  ul.mobile-meta-navi {
    display: none;
    border-top: none;
    padding: calculateRem(10px) calculateRem(10px) calculateRem(10px) calculateRem(10px);

    li {
      border: none !important;
      a {
        @include font-size(14,14);
      }
    }

    @media screen and (max-width: $m - 1) {
      display: block;
    }
  }
}
/*--- Mobile END */

