.container.events {
  @media screen and (min-width: $xs) {
    display: grid;
  }
  grid-template-columns: 23% auto;

  border-bottom: 1px solid $color-border;
  padding-bottom: calculateRem(30px);
  padding-top: calculateRem(30px);

  &:last-child {
    border-bottom: none;
  }

  h3 {
    order: 1;



    grid-column: 2;
    grid-row: 1 / 1;

    margin-top: 0;
  }

  > p, > div {
    order: 2;


    grid-column: 2;
    grid-row: 2 / 2 ;
    margin-bottom: 0;
    margin-top: 0;

  }

  figure {
    order: 3;


    grid-column: 1;
    grid-row: 1 / span 2;
    padding-bottom: 0;

    img {
      border: none;
    }

    &.small {
      width: 100%;
    }
  }

  label.show {
    display: inline-block;

    input {
      width: calculateRem(50px);
      margin-right: calculateRem(10px);

      &[type="number"] {
        display: inline;
      }
    }
  }

}
