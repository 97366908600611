.bueronummernsuche {

  form {

    #bueronummersuche {
      margin-top: 2rem;

      input {
        margin: 1rem 0;
      }

    }
  }


  b {
    font-weight: 500;
  }

  .item {
    padding-right: 20px;
    width: calc(50% - 20px);
    float: left;

    @media(max-width: 500px){
      float: none;
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}