@media print {
  html {
    .hidden-print, .sfg_form, #gtue-top-of-page, div.sidebar-containers nav {
      display: none !important;
    }

    body {
      color: black;
    }





    .gtue-copyright {
      margin: calculateRem(30px) 0;
      display: block;
    }

    &.sticky  {
      nav#gtue-top-of-page {
        position: static;

      }
    }



    #container .content {

      .info-container {
        background-color: transparent;
        padding: 0;

        h3, a {
          color: black;
          margin: 0;
        }
      }

      .slider-container {

        &::after {
          @include font-size(14,20);
          content: "Die Gallerie können Sie im Partnerportal von GTÜ anschauen.";
          color: black;
        }

        > ul {
         display: none;
        }
      }

      .dark {
        background-color: transparent;
        color: black;
      }

      h1 {
        text-align: left;

        &:before {
          display: none;
        }
      }

      .pagetitle {
        h1 {
          background: transparent;
          background-image: none;
          padding: 0;
          color: black;
        }
      }

      .content-container {
        box-decoration-break: clone;

        &.col-2 {
          > div  {
            width: 50%;
            float: left;

            &:first-child {
              padding-right: 30px;
            }
          }
        }
      }

      .embed16-9, .embed, .video-js {
        padding: 0;
        background-color: transparent;
        height: auto;

        .vjs-poster {
          display: none;
        }

        > iframe, > video {
          display: none;

        }

        &::after {
          @include font-size(14,20);
          content: "Das Video können Sie im Partnerportal von GTÜ anschauen.";
          color: black;
        }
      }

      table.table {
        page-break-inside: avoid;
      }

      div.sidebar-containers {
        .content-container {
          page-break-inside: avoid;
          width: 50%;
          float: left;
          margin-top: calculateRem(15px);
          padding-right: calculateRem(20px);
          > div, div.link-container {


          }
        }
      }

      .accordion > ul > li {
        page-break-inside: avoid;
        > div {
          max-height: none;

        }
      }

      .kba {
        input[type="text"],
        input[type="email"],
        input[type="tel"],
        input[type="date"],
        input[type="file"],
        input[type="password"],
        span.result,
        textarea,
        select {
         border: 1px solid black;
        }
      }
    }
  }
}
