.werbemittelpakete {

  form.sfg_form {
    max-width: 580px;

    .gridRow {
      margin-bottom: 1rem;
      &>div:first-child {
        padding-right: 0;
      }
    }

    .fieldType_select::before {
      top: 1.85rem;
    }

    #werbemittelpakete_terms_help {
      font-size: 14px;
      float: right;
      width: 550px;
    }
  }

  .plus {
    display: inline-block;
    background: url('/assets/images/icons/add_rot.png') no-repeat center center;
    background-size: 20px;
    width: 20px;
    height: 20px;
    position: relative;
    bottom: -4px;
  }

  #result {
    position: absolute;
    top: 17px;
    background: rgba(255,255,255,0.8);
    height: calc(100% - 48px);
    padding: 0.75rem 2rem 2rem 2rem;
    max-width: 580px;
    margin-top: 2rem;
  }

  .result-inner {
    border: 1px solid #ccc;
    padding: 20px;
    background: #fff;
    margin-top: 40px;
  }

  .wmp-form-wrapper {
    position: relative;
  }

  .close-conf {
    right: 20px;
    overflow: hidden;
    color: #fff;
    border: none;
    font-weight: 500;
    padding: 0.8125rem 2.8125rem;
    border-radius: 3.125rem;
    border-color: transparent;
    background-color: #555555;
    box-shadow: none;
    outline: none;
    font-size: 0.875rem;
    line-height: 0.875rem;
    cursor: pointer;
    transition: all ease-out 0.3s;
  }

  .dataTables_wrapper {
    table.dataTable {

      tr.show-mobile {
        display: none;
        @media(max-width: 500px) {
          display: table-row;
        }
      }

      table {
        tr:nth-child(4) td {
          border-top: 0;
        }
      }
    }
  }

  @media(max-width: 1070px){

    .dataTables_wrapper {

      .dataTables_info,
      .dataTables_paginate {
        font-size: 12px;
      }



      .dataTables_paginate {
        margin-top: 1rem;
      }

      table.dataTable {
        thead th {
          font-size: 12px;
          padding: 5px 18px 5px 5px;
        }

        tbody td {
          font-size: 12px;
          padding: 5px;
        }
      }
    }
  }
}
