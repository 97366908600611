
/*media query sizes*/
$xs: 450px;

$s: 768px;

$m: 992px;

$l: 1025px;

$xl: 1240px;

$xxl: 1375px;

/*columns*/
$col_padding: 16px;
$gutter: 60px;
$grid_gutter: 38px;


$container_width_s: 100%;
$container_width_m: 970px;
/*$container_width_l: 1000px;*/
$container_width_xl: 1170px;
$container_width_xxl: 1350px;

$left_col_width: 240px;
$left_col_width_m: 200px;

$right_col_width: 240px;
$right_col_width_m: 200px;

$logo_width: 320px;

$middle_col_width_s: 100%;
$middle_col_width_m: $container_width_m - $left_col_width_m - $right_col_width_m ;
$middle_col_width_xl: $container_width_xl - $left_col_width - $right_col_width ;
$middle_col_width_xxl: $container_width_xxl - $left_col_width - $right_col_width ;

/*video and image */
$img_width: 100%;

$video_width: 100%;

/*decoration*/
$hr_height: 3px;
$sidebar_underline_height: 1px;

/* Tables */
$table-cell-padding: calculateRem(12px);

/* Margins */
$m1: 0.5rem;
$m2: 1rem;
$m3: 1.5rem;
$m4: 2rem;
$m5: 3rem;

.mb-1 {
  margin-bottom: $m1 !important;
}

.mt-1 {
  margin-top: $m1 !important;
}

.mb-2 {
  margin-bottom: $m2 !important;
}

.mt-2 {
  margin-top: $m2 !important;
}

.mb-3 {
   margin-bottom: $m3 !important;
}

.mt-3 {
  margin-top: $m3 !important;
}

.mb-4 {
  margin-bottom: $m4 !important;
}

.mt-4 {
  margin-top: $m4 !important;
}

.mb-5 {
  margin-bottom: $m5 !important;
}

.mt-5 {
  margin-top: $m5 !important;
}

