table {
  width: 100%;
  max-width: 100%;
  @include font-size(14,18);
  border-spacing: 0;
  border-collapse: separate;
  margin-bottom: calculateRem(50px);
  border-bottom: 1px solid $grey_50;

  th,
  td {
    padding: calculateRem(15px) calculateRem(12px);
    vertical-align: top;
  }

  thead th, thead td {
    vertical-align: bottom;
    //border-bottom: (2 * $table-border-width) solid $grey_50;
    background-color: $grey_60;
    padding: calculateRem(10px) calculateRem(12px);
    color: white;
    @include font-size(16,20);
    font-weight: 500;
  }

  tbody {
    tr{
      &:first-child {
        td {
          border-top: none;
        }
      }
      td {
        border-top: 1px solid $grey_50;
      }
    }
  }
/*
  tr:hover {
    background-color: $grey_20;
  }
  */

  tfoot {
    td {
      border-top: 1px solid $grey_50;
      //background-color: $grey_20;
    }
  }
}


.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057
}

.top-scroller {
  display: none;
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  .sticky & {
    top: calculateRem(43px);
    position: fixed;

  }
}

/*-------------------
Display Tables START
---------------------*/
div.table {
  display: table;
  .table-row {
    display: table-row;

    .table-cell {
      display: table-cell;
    }
  }
}
/*--- Display Tables END */


/*-------------------
GTUE Table Style
---------------------*/

.gtue-table {
  border: 1px solid $grey_50;
  border-bottom: 0;

  td, th {
    text-align: left;
    border-bottom: 1px solid $grey_50;
    border-top: 0;

    p {
      margin: 0;
    }
  }


  thead {
    th {
      background: $grey_40;
      color: $black;
      font-weight: 500;
      font-size: 14px;
      padding: 0.9375rem 0.75rem;
    }
  }

  tbody {
    tr:nth-child(even) {background: #f6f6f6}
    tr:nth-child(odd) {background: #FFF}
  }

}
