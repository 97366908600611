#themen {

  /* ---- input ---- */

  input[type="text"] {
    background: #f2f2f2;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C2C4C6;
    outline: none !important;
    padding: 5px;
    font-size: 16px;
  }

  /* ---- button ---- */

  .button {
    display: inline-block;
    padding: 0.5em 1.0em;
    background: #f2f2f2;
    border: none;
    border-radius: 15px;
    color: #323234;
    text-decoration: none !important;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in;

    h3 {
      font-weight: 500;
      position: relative;
      top: 48%;
      transform: translateY(-48%);
      margin: 0;
    }

    &:hover {
      background-color: #da1f3d;
      color: #323234 !important;
    }

    &:active,
    &.is-checked {
      background-color: #da1f3d;
      color: #323234 !important;
    }
  }

  .grid-wrapper {
    display: none;
  }

  /* ---- button-group ---- */

  .button-group {
    &:after {
      content: '';
      display: block;
      clear: both;
    }

    .button {
      float: left;
      margin-left: 0;
      margin-right: 1px;
      width: 190px;
      height: 100px;
    }
  }

  .sub-categories {
    margin-top: 2rem;

    .button {
      width: auto;
      height: auto;
      padding: 10px 20px;
      font-weight: 500;

      &:hover {
        background-color: #555;
        color: #fff !important;
      }

      &:active,
      &.is-checked {
        background-color: #555;
        color: #fff !important;
      }
    }

    ul,
    li {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  /* ---- isotope ---- */

  .grid {
    max-width: 1200px;
    margin-top: 2rem;
  }

  /* clear fix */
  .grid:after {
    content: '';
    display: block;
    clear: both;
  }

  /* ui group */

  .ui-group {
    display: inline-block;
  }

  .ui-group h3 {
    display: inline-block;
    vertical-align: top;
    line-height: 32px;
    margin-right: 0.2em;
    font-size: 16px;
  }

  .ui-group .button-group {
    display: inline-block;
    margin-right: 20px;
  }


  .element-item {
    position: relative;
    float: left;
    width: 240px;
    height: 300px;
    margin: 5px 10px 5px 0;
    padding: 0;
    background: #888;
    color: #262524;
  }

  .element-item > * {
    margin: 0;
    padding: 0;
  }

  .element-item {
    background: #fff;
    border-radius: 10px;
    border: 2px solid #eee;
  }

  .item-inner {
    padding: 15px;
    font-size: 14px;
    line-height: 1.3rem;
  }

  .item-inner p {
    margin: 0;
  }

  .item-inner h3 {
    margin: 0;
    margin-top: .25rem;
    font-size: 14px;
    line-height: 1.2rem;
    margin-bottom: 0.25rem;
    font-weight: 500;
  }

  .filters {
    margin-top: 2rem;
  }

  .filter {
    width: 200px;
    height: 110px;
    float: left;
  }

  .filter h3 {
    text-align: center;
    font-size: 16px;
  }

  .sub-category {
    display: none;
  }

  .element-item header {
    position: static;
    border-bottom: 0;
    height: 80px;
    background-size: cover
  }
}