.content {
  p {
    margin-bottom: calculateRem(20px);
  }

  .tinymce ul:not([class^="list-"]) {
    padding-left: calculateRem(14px);
    overflow: hidden;
    li {
      list-style-type: none;
      position: relative;
      padding-left: calculateRem(6px);

      &::before {
        position: absolute;
        left: calculateRem(-7px);
        top: calculateRem(2px);
        content: '•';

      }
    }
  }

  ul, ol {
    padding-left: calculateRem(25px);
    margin-bottom: calculateRem(20px);

    li {
      margin-bottom: calculateRem(5px);
    }

    &[class^="list-"] {
      padding-left: calculateRem(2px);

      li {
        list-style-type: none;
        position: relative;
        padding-left: calculateRem(20px);

        &::before {
          position: absolute;
          left: calculateRem(-7px);
          top: calculateRem(2px);
          @include icon();

        }
      }
    }

    &.list-plus {
      li {
        &::before {
          content: '\e68f';
        }
      }
    }

    &.list-minus {
      li {
        &::before {
          content: '\e68e';
        }
      }
    }

    &.list-check {
      li {
        &::before {
          content: '\e60c';
        }
      }
    }
  }
}

.content-container {
  .info-container {
    padding: calculateRem(35px) calculateRem(35px) calculateRem(35px) calculateRem(35px);
    margin-bottom: calculateRem(30px);

    h3,h4 {
      &:first-child {
        margin-top: 0;
      }
    }

    .media-container {
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      + h3 {
        margin-top: 0;
      }
    }
  }

  .contact-container {
    .position {
      margin-bottom: calculateRem(5px);
      display: block;
    }

    span.fax,
    a {
      display: block;
      margin-bottom: calculateRem(5px);
      text-decoration: none;
      font-weight: 500;

      i {
        @include font-size(21,24);
      }
    }
  }

  .veranstaltungen-container {
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    strong  {
      font-weight: 500;
    }
  }
}
