@use "sass:math";

video {
  width: 100%;
}

.video-js {
  &.vjs-fluid {
    height: auto;
  }

  .vjs-poster {
    background-size: cover;
  }

  .vjs-big-play-button {
    border: none;
    background-color: rgba(255,255,255,.8);
    width: calculateRem(30px);
    height: calculateRem(30px);
    border-radius: 100%;
    left: 50%;
    margin-left: calculateRem(-15px);
    top: 50%;
    margin-top: calculateRem(-15px);

    span {
      @include font-size(20,30);
      color: $color-border;
    }
  }
}

.embed {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;

  &.ratio-16-12 {
    padding-bottom: (math.div(100, 16)*12) * 1%;
  }

  &.ratio-16-11 {
    padding-bottom: (math.div(100, 16)*11) * 1%;
  }

  &.ratio-16-10 {
    padding-bottom: (math.div(100, 16)*10) * 1%;
  }

  &.ratio-16-9 {
    padding-bottom: (math.div(100, 16)*9) * 1%;
  }

  &.ratio-16-8 {
    padding-bottom: (math.div(100, 16)*8) * 1%;
  }

  &.ratio-4-3 {
    padding-bottom: (100*0.25*3) * 1%;
  }

  &.ratio-5-4 {
    padding-bottom: (100*0.2*4) * 1%;
  }

  &:not(.noratio) > iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }

  &.noratio {
    height: auto;

    & > iframe {
      position: static;
      max-width: 100%;
      border: 0;
    }
  }
}