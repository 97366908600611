.kba-fahrzeugdaten {

  .btn-std {

  }




}

.kba-suchergebnis {

  a {
    color:#BA002E;
    text-decoration:none;
  }
  a:hover {
    text-decoration:underline;
  }

  .max-result {
    background: #ddd;
    padding: 0.5rem 1rem;
    border: 1px solid #bbb;
    cursor: pointer;
    margin-top: 1rem;
  }

  .max-result:hover {
    background:#BA002E;
    color: #fff;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
    background: #ddd;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background:#BA002E;
    color: #fff !important;
  }

  .criteria {
    margin-bottom: 2rem;
  }

  .criteria h2 {
    margin-bottom: 0;
    padding-left: 0;
  }

  .search-keys {
    list-style-type: none;
    padding-left: 0;
    font-size: 16px;
  }

  h2 {
    font-size: 16px;
  }

  .toggle {
    width: 798px;
    margin-top: 1rem;
    border: 1px solid #ccc;
    margin-bottom: 2rem;
  }

  .toggle-col {
    margin: 0.6rem;
    display: none;
  }

  .toggle-col .ui-state-active,.toggle-col .ui-widget-content .ui-state-active,.toggle-col  .ui-widget-header .ui-state-active,.toggle-col a.ui-button:active,.toggle-col .ui-button:active,.toggle-col .ui-button.ui-state-active:hover {
    background: #BA002E;
    border-color: #BA002E;
  }
  .toggle-trigger {
    cursor: pointer;
    padding: 7px 14px;
    margin: 0;
  }

  .toggle-trigger:hover {
    color: #BA002E;
  }

  .dataTables_wrapper .dataTables_length {
    text-align: left !important;
  }

  .info {
    margin-bottom: 1rem;

    ul li {
      display: inline-block;
      border: 1px solid #ccc;
      padding: 5px 15px;
      margin-right: 7px;

      &.deleteable {
        background:#BA002E;
        border-color: #BA002E;
        color: $white;
        cursor: pointer;

        i {
          padding-left: 5px;
        }
      }
    }

    h2 {
      width: 798px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 5px;
    }
  }

  .loader {
    width: 200px;
    height: 50px;
    background: url('./../images/loader.gif') no-repeat left center;
  }

  .meta-block {
    overflow: hidden;
  }

  .meta-block .ui-button {
    margin-right: .2em;
    margin-bottom: .5em;
  }

  .suchergebnis_ext table.dataTable td.dataTables_empty {
    text-align: left;
  }
  /*.suchergebnis_ext { font-size: 10px; }*/

  th { background-color: #eee; text-align: left; border: 1px solid #ccc }

  td input { padding: 3px; border: 1px solid #ccc; }
  td input.disabled { background: #f6f6f6 }

  table.dataTable .detail-search td {
    background: #fff;
  }

  table.dataTable thead th { background-color: #eee; text-align: left; border: 1px solid #ccc;}
  table.dataTable thead td,
  table.dataTable thead th{padding: 8px 10px;}

  table { width:auto; margin-bottom:0.5em; margin-top:0.5em; }
  table.dataTable {border-collapse:collapse;}

  /*.suchergebnis_ext tbody td, thead th { border:1px solid Black; padding:0.2em; }*/
  tbody td.keinelinie { border:0px;}

  tr.farbzelle { background-color: #D3D3D3; }

  table.results tr.even {
    background: #f6f6f6;
  }

  table.results tr.odd {
    background: #fff
  }

  table.results tr td {
    border: 1px solid #ccc;
  }

  table.results tbody tr:hover td {
    background: #BA002E;
    cursor: pointer;
  }

  table.results tbody tr:hover td.dataTables_empty {
    background: #fff;
    cursor: default;
  }

  table.results tbody tr:hover td,
  table.results tbody tr:hover td a {
    color: #fff;
  }

  .dataTables_wrapper .dataTables_length {
    margin-bottom: 1rem;
  }

  .dataTables_wrapper select {
    padding: 3px;
  }

  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: left;
    margin-bottom: 1rem;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button.previous {
    padding-left: 0;
    margin-left: 0;
  }

  .order span {
    cursor: pointer;
  }

  input[type="text"],input.gelb[readonly]
  {
    background-color:#FFFF80;
    font-family:verdana,arial,sans-serif;
    font-size:12px;
    color:#000000;
  }
  input.gruen[type="text"]
  {
    background-color:#80FF00;
    font-family:verdana,arial,sans-serif;
    font-size:12px;
    color:#000000;
  }
  input[readonly]
  {
    background-color:#F0F0F0;
  }

  textarea
  {
    background-color:#FFFF80;
  }

  textarea[readonly]
  {
    background-color:#F0F0F0;
  }

  .ui-widget input
  {
    background-color:#FFDC90;
  }





  @media print {
    .noprint {display:none}
  }

  @media screen {
    .noshow {display:none}
  }
}