
/*-------------------
Slider START
---------------------*/
.content .slider-container {
	@extend %simpleScroller;
	//eight: calculateRem(180px);
	//background-color: $boxColorBack;
	overflow: hidden;
	height: 100%;
	//background-color: $grey_80;
	padding: calculateRem(20px);
	margin-bottom: calculateRem(30px);

	> ul {
		> li {
			width: calculateRem(190px);
			height: calculateRem(135px);
			margin: 0 calculateRem(7px);

			a {
				background-size: cover;
				display: block;
				width: 100%;
				height: 100%;
				position: relative;

				.media-description {
					opacity: 0;
					@include transition(opacity,.3s);
					background-color: $grey_80;
					color: white;
					position: absolute;
					font-weight: 400;
					bottom: 0;
					padding: calculateRem(20px) calculateRem(10px) calculateRem(10px) calculateRem(10px);

					&::before {
						top: calculateRem(-21px);
						right: calculateRem(10px);
						left: auto;
						color: white;
						background-color: $grey_80;
						border-radius: 100%;
						padding: calculateRem(9px);

					}
				}

				&:hover,&:focus {
					.media-description {
						opacity: 1;
					}
				}

				&::after {
					display: none;
				}
			}

			img {
				border: none;
			}
		}
	}
}

/*--- Slider END */

%simpleScroller {
	position: relative;

	& > ul {
		position: relative;
		z-index: 1;
		list-style: none;
		margin: 0;
		padding: 0;
		overflow: hidden;
		overflow-x: auto;
		white-space: nowrap;

		& > li {
			display: inline-block;
			position: relative;
			white-space: normal;
			padding: 0;
		}
	}

	.scroller_left,
	.scroller_right {
		position: absolute;
		z-index: 2;
		overflow: hidden;
		background: $grey_20;
		color: $grey_80;
		width: calculateRem(34px);
		height: calculateRem(73px);
		text-align: center;
		font-weight: bold;
		border-radius: 0 40px 40px 0;
		left: 0;
		cursor: pointer;
		top: 50%;
		margin-top: calculateRem(-37px);
		border: none;


		&:hover, &:focus, &:active {
			outline: none;
			color: $color-highlight;
		}

		&::before {
			@include icon();
			position: relative;
			@include font-size(50,68);
			line-height: 1;
			top: calculateRem(10px);
			margin: 0;
		}
	}

	.scroller_left {

		&::before {
			content: "\e611"; //"?"
			left: calculateRem(-18px);
		}

	}

	.scroller_right {
		right: 0;
		border-radius: 40px 0 0  40px;
		left: auto;
		&::before {
			content: "\e60f"; //"?"
			left: calculateRem(-13px);
		}
	}

	.scroller_left::before,
	.scroller_right::before {
		display: inline-block;
		vertical-align: middle;
	}

	.scroller_left::after,
	.scroller_right::after {
		content: "";
		width: 0;
		height: 100%;
		display: inline-block;
		vertical-align: middle;
	}

	.scroller_counter {
		text-align: center;

		a {
			box-sizing: border-box;
			display: inline-block;
			width: 1.5em;
			height: 1.5em;
			margin: 0 0.25em;
			border: 1px solid #000000;
			border-radius: 100%;

			&.on {
				background: #000000;
				color: #FFFFFF;
			}
		}
	}
}
